/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import { isLoggedIn } from "../../../utils/auth.util";
import * as userUtil from "../../../utils/user.util";
import Menu from "../../../Assets/Images/dots.png";
import * as authUtil from "../../../utils/auth.util";
import "../../js/Playlist/playlist.scss";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import { useDispatch } from "react-redux";
import { getMusicData } from "../../../store/reducers/MusicData/action";
import LoadingBalls from "../../../Assets/LoadingBalls";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import * as userUtils from "../../../utils/user.util";
import useOnClickOutside from "../../../helpers/outsideClick";
import debounce from 'lodash.debounce';
import { Dropdown } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";




export default function Play() {
  const userId = userUtils.getUserInfo()?.user?.id;

  const route = window.location.pathname;
  var playlistId = route.split("/").slice(-1)[0];
  const [playlistData, setPlaylistdata] = useState();
  const [palylistLastimage, setPlaylistLastImage] = useState();
  const [highlight, setHighlight] = useState();
  const [dataChange, setDataChange] = useState();
  const [loading, setLoading] = useState(false);
  const [dId, setDownloadId] = useState();
  const user = userUtil.getUserInfo();
  const [userPlaylist, setUserPlaylist] = useState([]);
  const history = useNavigate();
  const dispatch = useDispatch();
  const [playlistModal, setPlaylistModal] = useState({
    open: false,
    musicId: null,
  });
  const allVideos = useSelector((state) => state.MusicData);
  console.log("allvideos", allVideos);

  useEffect(() => {
    getByUserPlaylist();
  }, []);

  const [open, setOpen] = useState({});
  
  const getByUserPlaylist = async () => {
    await ApiGet("playlist/get-by-user")
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          setUserPlaylist(res.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPlaylistData();
  }, [dataChange]);

  const handleAddToPlaylist = async (id) => {
    const data = {
      playlistId: id,
      musicId: playlistModal.musicId,
    };
    setLoading(true);
    await ApiPost("playlist/add-to-playlist", data)
      .then((res) => {
        setLoading(false);
        if (res.data.message.includes("exists")) {
          toast.error("Song is already added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setPlaylistModal({ open: false });
          toast.success("Song is added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const downloadMusic = async (id) => {
    console.log("Downloading");
    await ApiGet(`music/download?musicId=${id}`)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSendEmail = async (id) => {
    const bodyId = {
      musicId: id,
      userId: userId,
    };
    await ApiPost(`downloadUser/downloadUsers`, bodyId)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handledownload = async (data) => {
    toast.success("Your download will begin shortly");
    let url = data.musicUrl;
    let title = data?.musicTitle ? data?.musicTitle : data?.title;

    await axios({
      url: url,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "video/mpeg",
      },
    })
      .then(async (response) => {
        handleSendEmail(data._id);
        console.log("object");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.mp3`); //or any other extension
        document.body.appendChild(link);
        downloadUserList(data);
        await downloadMusic(data._id);
        link.click();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const downloadUserList = async (data) => {
    const body = {
      musicId: data._id,
      userId: userId,
      artistId: data.artistId._id,
    };

    await ApiPost(
      `musicDownloadListForArtist/create-musicDownloadListForArtist`,
      body
    )
      .then((res) => {
        console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxx", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const shareLink = (id) => {
    navigator.clipboard.writeText(`${window.location.href}song/${id}`);
    toast.success("Link Copied..");
  };
  const [copied, setCopied] = useState(false);
  function copy() {
    const el = document.createElement("input");
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  const getPlaylistData = async () => {
    setLoading(true);
    await ApiGet(`playlist/${playlistId}`)
      .then((res) => {
        setLoading(false);
        console.log(res.data.data);
        setPlaylistdata(res.data.data);
        res.data.data.Songs.map((ress) => {
          setPlaylistLastImage(ress?.musicImage);
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  // console.log({palylistLastimage})
  const handleRemoveFromPlaylist = async (id) => {
    const data = {
      musicId: id,
      playlistId: playlistId,
    };
    await ApiPost("playlist/remove-from-playlist", data)
      .then((res) => {
        console.log("res", res);
        setDataChange("music removed");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const [show, setShow, setShowModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [selectedSongIndex, setSelectedSongIndex] = useState();

  const handleMusicClick = (index, record) => {
    setHighlight(index);

    if (isLoggedIn()) {
        !(!record.isPaid && userUtil?.getMusicData()?.includes(record._id))
        ? dispatch(
            getMusicData({
              playlistData: playlistData.Songs,
              index: index,
            })
          )
        : toast.error("Purchase the song first!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
    } else {
      
      dispatch(
        getMusicData({
          playlistData: playlistData.Songs,
          index: index,
        })
      );
    }
  };
  // play all free songs on a list one by one from page to page 
  const handlePlayuserPlaylist = (record, key) => {
    if (record.isPaid) {
       if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
       } else {
        setLoginModal(true);
       }
    } else {
      dispatch(getMusicData({playlistData: userPlaylist, index: key}));
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 p-1 m-1">
      <>
        <Header />
        
      </>
      <div className="grid" >
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box pt-12 mt-12">
            <div className="container-fluid">
             <div className="md:w-full pt-5 flex items-center ">
                <div className="md:w-full pt-5 flex items-center ">
                <NavLink to="/" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}>
                    <p>
                      <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                    </p>
                  </NavLink>
                  <p className="pl-3 font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                    Playlist
                  </p>
                </div>
              </div>
              {playlistModal.open ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="playlist border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div
                          className="justify-content-center p-5 relative  border-solid border-white-200 rounded-t"
                          // style={{
                          //   backgroundColor: "#090C10",
                          // }}
                        >
                          <h3 className="text-2xl text-center font-bold text-white">
                            Select Playlist
                          </h3>

                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                          >
                            <p className="button-center-modal">
                              <i className="fas fa-times"></i>
                            </p>
                          </button>
                        </div>
                        {/*body*/}
                        <div
                          className="relative p-6 flex-auto"
                          
                        >
                          <p className="my-4 text-white text-lg leading-relaxed">
                            <div
                              className="flex items-center flex-row flex-wrap pt-4"
                              style={{
                                height: "450px",
                                overflowY: "overlay",
                              }}
                            >
                              {userPlaylist && userPlaylist.length ? (
                                userPlaylist.map((record, index) => {
                                  return (
                                    <div
                                      className="pl-3 pr-3 cursor-pointer "
                                      key={index}
                                      onClick={() => {
                                        handleAddToPlaylist(record._id);
                                      }}
                                      OnTouchStart={() => {
                                        handleAddToPlaylist(record._id);
                                      }}
                                    >
                                      <div className="card-holder">
                                        <div className="singer-box">
                                          <div className="singer-img">
                                            <img
                                              src={
                                                (record &&
                                                  record.Songs &&
                                                  record.Songs.length &&
                                                  record.Songs[0].musicImage) ||
                                                `https://source.unsplash.com/1600x900/?${record.playlist_name}`
                                              }
                                              alt=""
                                              style={{
                                                height: "150px",
                                                width: "335px",
                                              }}
                                            />
                                          </div>
                                          <div className="singer-text p-4">
                                            <div className="flex items-center justify-between">
                                              <div  className="banner-img"
                                                     onClick={() =>
                                                    handlePlayuserPlaylist(record, index)}    // play all free songs on a list one by one from page to page
                                                   OnTouchStart={() => 
                                                 handlePlayuserPlaylist(record, index)}                         
                                              >
                                                <p className="font-size-14 white-text-color font-normal tracking-normal mb-0">
                                                  {record.playlist_name}
                                                </p>
                                              </div>
                                              <div>
                                                {record?.Songs?.map((rec) => {
                                                  if (
                                                    rec._id ===
                                                    playlistModal.musicId
                                                  ) {
                                                    return (
                                                      <>
                                                        <p className="cursor-pointer font-size-14 login-text-color">
                                                          added
                                                          <i className="pl-1 fas fa-check cursor-pointer font-size-14 login-text-color"></i>
                                                        </p>
                                                      </>
                                                    );
                                                  }
                                                })}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="justify-content-center widthFull">
                                  <h1 className="text-white text-2xl mt-10">
                                    Playlist Is Empty.
                                  </h1>
                                </div>
                              )}
                            </div>
                          </p>
                        </div>
                        {/*footer*/}
                        <div
                          className=" flex items-center justify-end p-4  border-solid border-white  "
                        >
                          <button
                            className="closebtn text-white border-solid border border-white font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}
              <div className="lg:flex pt-5">
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 w-full pl-3 pr-3">
                  <div
                    className="play-main-banner flex items-end"
                    style={{
                      backgroundImage: `url(${
                        playlistData?.Songs[0]?.musicImage ||
                        `https://source.unsplash.com/1600x900/?${playlistData?.playlist_name}`
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      width: "100%",
                      borderRadius: "25px",
                    }}
                  >
                    
                    
                    <div className="p-4 m-4">
                    <img
                      className="play-child-image-border-radius rounded-2xl p-4 m-4 "
                      src={
                        (palylistLastimage && palylistLastimage) ||
                        "https://cdn.dribbble.com/users/60166/screenshots/5394073/music_logo.jpg"
                      
                      }
                      alt=""
                       style={{
                        width: "200px",
                        height: "200px",
                        borderRadius: "30px",
                      }}
                      >
                    </img>
                      <h3 className="playlist-text  font-size-35">
                        {playlistData?.playlist_name}
                      </h3>
                      <h3 className="playlist-text ">
                        Total Music: {playlistData?.Songs.length}
                      </h3>
                    </div>
                  </div>
                <div className="pt-4"></div>
                <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1">
              <div className="play-table1">
            <table width="100%">
         <thead className="text-white">
       <tr>
        <td>Title</td>
        <td>Artist</td>
      </tr>
    </thead>
  <tbody className="play-table-details1">
    {playlistData?.Songs?.length ? (
      playlistData?.Songs?.map((record, index) => {
        return (
          <tr
            key={index}
            className={`${
              highlight
                ? highlight === index && "table-heighlite"
                : index === 0 && "table-heighlite"
            }`}
          >
            <td
              className="text-left cursor-pointer"
              onClick={() => handleMusicClick(index, record)}
              OnTouchStart={() => handleMusicClick(index, record)}

            >
              <span
                className={`font-size-16 tracking-normal font-medium cursor-pointer  ${
                  highlight
                    ? highlight === index && "white-text-color"
                    : index === 0 && "white-text-color"
                }`}
              >
                {record?.musicTitle}
              </span>
            </td>
             <td className="text-left cursor-pointer"
                 onClick={() => {
                  handleMusicClick(index, record);
                }}
                OnTouchStart={() => {
                  handleMusicClick(index, record);
                }}
              >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  className={`font-size-16 cursor-pointer tracking-normal font-medium ${
                    highlight
                      ? highlight === index && "white-text-color"
                      : index === 0 && "white-text-color"
                  }`}
                  onClick={() => {
                    handleMusicClick(index, record);
                    history(`/profile/${record.artistId._id}`);
                  }}
                  OnTouchStart={() => {
                    handleMusicClick(index, record);
                    history(`/profile/${record.artistId._id}`);
                  }}
                >
                  {record?.artistId.artistName}{" "}
                </span>
                    <div className="My_Dropdown "> 
                       <Dropdown show={index === selectedSongIndex}  //to avoid dropdown showing all at the same time 
                          onMouseEnter={() => {
                         setSelectedSongIndex(index)
                            }}
                           onMouseLeave={() => {
                         setSelectedSongIndex()
                            }}
                       >
                         <Dropdown.Toggle
                           variant="success"
                           id="dropdown-basic"
                           className="text-white"
                         >
                           <Tooltip title="More" arrow>
                             <i className="fas fa-ellipsis-v cursor-pointer font-size-10 text-white p-3"></i>
                           </Tooltip>
                         </Dropdown.Toggle>
                         { index === selectedSongIndex &&(<Dropdown.Menu>
                           <div
                             className="flex flex-col w-32 max-w-full box-shadow-cus border-color"
                             style={{
                               backgroundColor: "#090C10",
                             }}
                           >
                             <div
                               className="text-white p-2 cursor-pointer"
                               onClick={() =>
                                 authUtil.isLoggedIn()
                                   ? setPlaylistModal({
                                       musicId: record._id,
                                       open: true,
                                     })
                                   : setLoginModal(true)
                               }
                               OnTouchStart={() =>
                                 authUtil.isLoggedIn()
                                   ? setPlaylistModal({
                                       musicId: record._id,
                                       open: true,
                                     })
                                   : setLoginModal(true)
                               }
                             >
                               Add to playlist
                             </div>
                          <div
                               className="text-white p-2 cursor-pointer"
                               onClick={() =>
                                 history(`/song/${record._id}`)
                               }
                               OnTouchStart={() =>
                                 history(`/song/${record._id}`)
                               }
                             >
                               Details
                             </div>
                             {/* {!record.isPaid && ( */}
                               <div
                                 className="text-white p-2 cursor-pointer"
                                 onClick={() => {
                                   setDownloadId(record._id);
                                   console.log(
                                     "Download Onclick",
                                     "Download OnTouchStart",
                                     record._id
                                   );
                                   authUtil.isLoggedIn()
                                     ? handledownload(record)
                                     : setLoginModal(true);
                                 }}
                                 OnTouchStart={() => {
                                   setDownloadId(record._id);
                                   console.log(
                                     "Download Onclick",
                                     "Download OnTouchStart",
                                     record._id
                                   );
                                   authUtil.isLoggedIn()
                                     ? handledownload(record)
                                     : setLoginModal(true);
                                 }}
                               >
                                 Download
                               </div>
                             {/* )} */}
                             <div
                               className="text-white p-2 cursor-pointer"
                               onClick={() => {
                                 shareLink(record._id);
                               }}
                               OnTouchStart={() => {
                                 shareLink(record._id);
                               }}
                             >
                               <button onClick={copy} OnTouchStart={copy}>
                                 {!copied
                                   ? "Copy link"
                                   : "Copy link"}
                               </button>
                             </div>
                           </div>
                         </Dropdown.Menu>)}
                         
                       </Dropdown>
                     </div>
                   </div>
                 </td>
               </tr>
             );
           })
         ) : (
       <tr>
        <td colSpan="2" className="text-white" align="center">
          NO DATA FOUND <i className="far fa-frown-open"></i>
        </td>
       </tr>
          )}
           </tbody>
            </table>
              </div>
                </div></div>

                {loading && <LoadingBalls />}
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  closeOnTouchStart
                  rtl={false}
                  pauseOnFocusLoss={false}
                  draggable
                  pauseOnHover
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
