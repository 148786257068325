/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React, { useEffect, useState } from "react";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import renderHTML from "react-render-html";
import { ApiGet } from "../../../helpers/API/ApiData";

export default function ContentUsePolicy() {
  const [loading, setLoading] = useState(false);
  const [PolicyData, setPolicyData] = useState();

  ////////////////////////////////////// Terms and conditions  ////////////////////////////////////

  useEffect(() => {
    const getAllPolicy = async () => {
      setLoading(true);
      await ApiGet("contentUse/get-all-content-use")
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data.data);
            setPolicyData(res.data.data);
            setLoading(true);
          } else {
            console.log("error");
          }
        })
        .catch((err) => {
          setLoading(true);
          console.log(err);
        });
    };
    getAllPolicy();
  }, []);

  //////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      <Header />
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          {PolicyData ? (
            PolicyData?.slice(0)
              ?.reverse()
              .map((data, key) => {
                return (
                  <>
                    <div className="pt-24">
                      <div className="pl-10 pr-10">
                        <h2 className="adobe-font font-size-25 green-text-color tracking-normal pb-2">
                          {data?.title}
                        </h2>
                        <p className="font-size-18 white-text-color font-medium tracking-normal text-left pb-3">
                          {renderHTML(data?.description)
                            ? renderHTML(data?.description)
                            : ""}
                        </p>
                      </div>
                    </div>

                    {data?.content.map((content, key) => {
                      return (
                        <div className="">
                          <div className="pl-10 pr-10">
                            <p className="adobe-font font-size-18 green-text-color tracking-normal pb-2">
                              {content?.title}
                            </p>
                            <h2 className="font-size-18 white-text-color font-medium tracking-normal text-left">
                              {renderHTML(content?.description)
                                ? renderHTML(content?.description)
                                : ""}
                            </h2>
                          </div>
                        </div>
                      );
                    })}
                  </>
                );
              })
          ) : loading ? (
            <center>
              <div className="mt-36">
                <div className="dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </center>
          ) : (
            <></>
          )}
        </main>
      </div>
    </div>
  );
}
