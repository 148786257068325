/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React from "react";
import { NavLink } from "react-bootstrap";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";

const Search = () => {
  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              {/* new release */}
              <div className="md:flex">
                <div className="md:w-full pt-4 pl-3 pr-3">
                  <div className="flex items-center justify-between">
                    <p className="font-size-20 white-text-color font-bold tracking-normal cursor-pointer">
                      New Released
                    </p>
                    <NavLink to="/newrelease">
                      <p className="font-size-20 white-text-color font-bold tracking-normal cursor-pointer">
                        See All
                      </p>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="lg:flex pt-4"></div>
            </div>
          </div>
          {/* {loading && <LoadingBalls />} */}
        </main>
      </div>
      <div>
        {/* <>
                    <Footer musicData={selectedMusic} />
                </> */}
      </div>
    </div>
  );
};

export default Search;
