/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React, { useEffect, useRef, useState } from "react";
import "../../js/Trending/trending.scss";
import "../../../Components/styles/dashboard.scss";
import Sidebar from "../../Layout/Sidebar";
import Header from "../../Layout/Header";
import { NavLink, useNavigate } from "react-router-dom";
import { ApiGet } from "../../../helpers/API/ApiData";
import { useSelector, useDispatch } from "react-redux";
import { getMusicByCountry } from "../../../store/reducers/MusicByCountry/action";
import LoadingBalls from "../../../Assets/LoadingBalls";
import Country from "../countries/Country";
import * as authUtil from "../../../utils/auth.util";
import { getMusicData } from "../../../store/reducers/MusicData/action";
import useOnClickOutside from "../../../helpers/outsideClick";




const AllMusicByCountry = (props) => {
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const musicByCountry = useSelector((state) => state.MusicByCountry);
  const dispatch = useDispatch();
  const [countries, setCountries] = useState(Country?.Country);

  useEffect(() => {
    if (Object.keys(musicByCountry).length === 0) {
      getAllMusicByCountry();
    }
  }, [musicByCountry]);

  const getAllMusicByCountry = async () => {
    setLoading(true);
    try {
      const res = await ApiGet("music/get-all-country-music");
      if (res?.data?.data !== "Record list is empty") {
        console.log("Fetched data:", res.data.data);
        dispatch(getMusicByCountry(res.data.data));
      } else {
        console.log("No records found");
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      alert("An error occurred while fetching data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const countryList = Object.keys(musicByCountry);
  const [loginModal, setLoginModal] = useState(false);
  const [showModal, setShowModal] = useState({
    open: false,
    musicId: null,
    amount: null,
    musicTitle: null,
    artistId: null,
  });

  const [open, setOpen] = useState({});
  const NavigatorRef2 = useRef();

  useOnClickOutside(NavigatorRef2, () => {
    setOpen({});
  });

  // play all free songs on a list one by one from page to page 
  const handlePlaycountryList = (record, key) => {
    if (record.isPaid) {
       if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
       } else {
        setLoginModal(true);
       }
    } else {
      dispatch(getMusicData({playlistData: countryList, index: key}));
    }
  };

  return (
    <div>
      <Header />
      <div className="flex">
        <Sidebar />
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex pt-24 pt-24-mobile">
                <div className="md:w-full">
                  <div className="pt-5 flex items-center pt-5-mobile">
                    <NavLink
                      to="/"
                      onClick={(e) => {
                        e.preventDefault();
                        history(-1);
                      }}
                     
                    >
                      <p>
                        <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                      </p>
                    </NavLink>
                    <p className="pl-3 font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                      Music By Country
                    </p>
                  </div>
                </div>
              </div>

              <div className="cus-grid pt-4">
                {countryList && countryList.length ? (
                  countryList?.map((record, key) => {
                    const country = countries.find((c) => c.Name === record);
                    const imagePath = country
                      ? require(`../../../Assets/Countries/${country.Code.toLowerCase()}.png`)
                      : null;

                    return (
                      <div className="grid-items" key={key}>
                        <div
                          onClick={() => {
                            history(`/musicbycountry/${record}`, { state: { recordName: record } });
                          }}
                          
                        >
                          <div className="singer-box">
                            <div className="singer-img">
                              <img
                                src={imagePath}
                                alt={record}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "../../../Assets/Countries/default.png";
                                }} // Fallback image
                              />
                            </div>
                            <div className="singer-text p-5 mini-view-p-remove">
                              <NavLink to={`/musicbycountry/${record}`}>
                                <div className="flex items-center justify-between cursor-pointer">
                                  <div>
                                    <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                      {record}
                                    </p>
                                  </div>
                                </div>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="dots mt-10">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
        {loading && <LoadingBalls />}
      </div>
    </div>
  );
};

export default AllMusicByCountry;