/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import { useMemo, useState } from "react";
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useRef } from "react";
import { ApiPost } from "../../helpers/API/ApiData";
import Bar from "../Player/Bar";
//import useAudioPlayer from "../Player/useAudioPlayer";
import "./Waves.css";
import "./Bar.css";
import "./MusicLoader.css";
import { useSelector } from "react-redux";
import * as userUtils from "../../utils/user.util";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import debounce from 'lodash.debounce';
import FastClick from 'fastclick';



export default function Footer() {
  const allGenre = useSelector((state) => state.AllGenre);

  const history = useNavigate();
  const navigate = useNavigate();
  const userId = userUtils?.getUserInfo()?.user.id;
  const [index, setIndex] = useState();
  const [FreeIndex, setFreeIndex] =useState();
  const selectedMusicIsFree = useState();

  // where we are receiving the clicked song
  const selectedMusicSource = useSelector((state) => state.MusicData);

  const [musicArray, setMusicArray] = useState();
  const [aId, setAid] = useState();
  const [listLength, setListLength] = useState(1);
  const [artistName, setArtistName] = useState("");
  const [realName, setrealName] = useState("");
  const [title, setTitle] = useState("");
  const [skipEnable, setSkipEnable] = useState(false);
  const [musicImage, setMusicImage] = useState();
  const location = useLocation();
  const artId = useParams();

  const [allSongs, setAllSongs] = useState([]);
  const [allFreeSongs, setAllFreeSongs] = useState([]);


  // clicking on title then it's plays the song
  useEffect(() => {
    console.log("===> ", JSON.stringify( selectedMusicSource, null, 2));
    if (
      selectedMusicSource === undefined
    ) {
      return;
    }
    if (selectedMusicSource.playlistData !== undefined && Array.isArray(selectedMusicSource.playlistData)) {
      setAllSongs(selectedMusicSource.playlistData);
      console.log("index: " + selectedMusicSource.index);
      setIndex(0);
      if (selectedMusicSource.index) {
        setIndex(selectedMusicSource.index);
      }
      else {
        setIndex(0);
      }
      return;
    }

    console.log('just here::', selectedMusicSource.playlistData);

    if (selectedMusicSource.playlistData !== undefined && Array.isArray(selectedMusicSource.playlistData.Songs)) { 
      console.log('Sensei Alex Sylvain Luenga, Yes');
      setAllSongs(selectedMusicSource.playlistData.Songs);
      setIndex(0);
      return;
    }

    if (selectedMusicSource.musicUrl !== undefined) {
      setAllSongs([selectedMusicSource]);
      setIndex(0);
      return;
    }

    if ( selectedMusicSource._id) {
      setAllSongs([selectedMusicSource]);
      setIndex(0);
      return;
    }

    if (selectedMusicSource.all === undefined) {
      return;
    }

    setAllSongs(selectedMusicSource.all);
    var index = selectedMusicSource.all.findIndex(
      (e) => e.musicUrl === selectedMusicSource.musicUrl
    );
    if (index !== -1) {
      setIndex(index);
    } else {
      setIndex(0);
    }
  }, [selectedMusicSource]);

  const selectedMusic = useMemo(() => {
    return allSongs[index];
  }, [allSongs, index]);


  useEffect(() => {
    if (selectedMusic?.index !== undefined) {
      {
        setIndex(selectedMusic?.index);
        setListLength(selectedMusic?.playlistData?.Songs.length);
        setMusicArray(selectedMusic?.playlistData?.Songs);
        setArtistName(selectedMusic?.playlistData?.Songs[selectedMusic?.index]?.artistId?.artistName );
        setrealName(selectedMusic?.playlistData?.Songs[selectedMusic?.index]?.artistId 
            ?.realName
        );
        setTitle(
          selectedMusic?.playlistData?.Songs[selectedMusic?.index]?.musicTitle
        );
        setMusicImage(
          selectedMusic?.playlistData?.Songs[selectedMusic?.index]?.musicImage
        );
        setSkipEnable(true);
      }
    } else {
      setSkipEnable(false);
    }
  }, [selectedMusic]);

  useEffect(() => {
    if (!allSongs) {
      return;
    }
    //playingSong = musicArray?.[index]?.musicUrl;

    setArtistName(allSongs?.[index]?.artistId?.artistName);
    setrealName(allSongs?.[index]?.artistId?.realName);
    setTitle(allSongs?.[index]?.musicTitle);
    setMusicImage(allSongs?.[index]?.musicImage);
  }, [allSongs, index]);

  /*var playingSong = selectedMusic?.musicUrl
    ? selectedMusic?.musicUrl
    : musicArray
    ? musicArray[index]?.musicUrl
    : {};*/

  const playingSong = useMemo(() => {
    const song = allSongs[index];
    if (song === undefined) {
      return undefined;
    }
    return song.musicUrl;
  }, [allSongs, index]);

  var track = playingSong;

  const [isPlaying, setIsPlaying] = useState(false);
  const [trackProgress, setTrackProgress] = useState(0);
  const [volume, setVolume] = useState();
  const [isMuted, setIsMuted] = useState(false);
  const [onLoop, setOnLoop] = useState(false);
  const [onShuffle, setOnShuffle] = useState(false);

  const toNextTrack = () => {
    if (index < allSongs.length) {
      setIndex(index + 1);
    }
  };
  const toPrevious = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  // Refs
  const audioRef = useRef(new Audio(track));
  const intervalRef = useRef();
  const isReady = useRef(false);
  // Destructure for conciseness
  const { duration } = audioRef.current;

  // const currentPercentage = duration
  //   ? `${(trackProgress / duration) * 100}%`
  //   : "0%";
  //   const trackStyling = `
  //    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
  //  `;
  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        setIsPlaying(false);
        // toNextTrack();
        toNextTrack();
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };
  const onScrub = (value) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubVolume = (value) => {
    audioRef.current.volume = value;
    setVolume(audioRef.current.volume);
    setIsMuted(false);
  };

  const resetVolume = () => {
    setIsMuted(false);
  };
  const setAudioVolumeMute = () => {
    isMuted
      ? (audioRef.current.volume = volume)
      : (audioRef.current.volume = 0);
    isMuted ? setIsMuted(false) : setIsMuted(true);
  };

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  useEffect(() => {
    if (isPlaying) {
      var resp = audioRef.current.play();
      if (resp !== undefined) {
        resp
          .then((_) => {
            // autoplay starts!
          })
          .catch((error) => {
            //show error
          });
      }
      resetVolume();
      startTimer();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying, startTimer]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  // Handles cleanup and setup when changing tracks
  useEffect(() => {
    audioRef.current.pause();
    audioRef.current = new Audio(track);
    setTrackProgress(audioRef.current.currentTime);
    setVolume(() => audioRef.current.volume);
    FastClick.attach(document.body); // to avoid delay on click or double press

    

    if (isReady.current) {
      var resp = audioRef.current.play();
      if (resp !== undefined) {
        resp
          .then((_) => {
            // autoplay starts!
          })
          .catch((error) => {
            //show error
          });
      }

      setIsPlaying(true);
      startTimer();
      resetVolume();
    } else {
      // Set the isReady ref as true for the next pass
      isReady.current = true;
    }
  }, [track, index, selectedMusic]);

  const handleShuffle = () => {
    setOnShuffle(!onShuffle);
  };
  const handleLoop = () => {
    audioRef.current.loop = !onLoop;
    setOnLoop(!onLoop);
  };

  // function formatDuration(duration) {
  //   return moment
  //     .duration(duration, "seconds")
  //     .format("mm:ss", { trim: false });
  // }

  const goToPreviousPath = () => {
    navigate(goToPreviousPath);
  };
  
  return (
    <div>
      <div className="footer-background">
        <div
          className="container-fluid"
          style={{
            pointerEvents: track ? "" : "none",
          }}
        >
          <input
            type="range"
            value={trackProgress}
            step="1"
            min="0"
            max={duration ? duration : `${duration}`}
            onChange={(e) => {
              e.preventDefault();
              onScrub(e.target.value);
            }}
            onMouseUp={onScrubEnd}
            onKeyUp={onScrubEnd}
            style={{
              width: "100%",
              cursor: "pointer",
            }}
          />
          <div className="lg:flex items-center ">
            <div className="lg:w-1/3 pl-3 pr-3 m-pl-0 m-pr-0">
              <div className="flex items-center m-center">
                <div
                  className="pl-4"
                  onClick={() => {
                    toPrevious();
                  }}
                  OnTouchStart={() => {
                    toPrevious();
                  }}
                >
                  <img
                    height="20"
                    width="20"
                    style={{ opacity: `${skipEnable ? "100%" : "99%"}` }}
                    src={require("../../Assets/Images/left.svg").default}
                    alt=""
                  />
                </div>

                <div className="pr-4 pl-4">
                  <div className="play-btn cursor-pointer flex items-center justify-center">
                    {isPlaying ? (
                      <img
                        src={require("../../Assets/Images/pause.svg").default}
                        onClick={() => setIsPlaying(false)}
                        OnTouchStart={() => setIsPlaying(false)}
                        alt=""
                      />
                    ) : (
                      <img
                        src={require("../../Assets/Images/play.svg").default}
                        onClick={() => setIsPlaying(true)}
                        OnTouchStart={() => setIsPlaying(true)}
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div
                  className="pr-4"
                  onClick={() => {
                    toNextTrack();
                  }}
                  OnTouchStart={() => {
                    toNextTrack();
                  }}
                >
                  {/* <i className="fas fa-pause"></i> */}
                  <img
                    height="20"
                    width="20"
                    style={{ opacity: `${skipEnable ? "100%" : "99%"}` }}
                    src={require("../../Assets/Images/right.svg").default}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="lg:w-1/3"
              onClick={() => {
                // setAid(selectedMusic);
              }}
            >
              <div className="flex flex-column items-center cursor-pointer justify-center">
                {/* <p className='white-text-color'>Mow Playing:</p> */}
                {selectedMusic?.thumbnail ||
                selectedMusic?.musicImage ||
                musicImage ? (
                  <div
                    className="pl-5 d-flex align-content-center"
                    onClick={() => {
                      navigate(`/song/${selectedMusic?._id}`);
                    }}
                    OnTouchStart={() => {
                      navigate(`/song/${selectedMusic?._id}`);
                    }}
                  >
                    <Link to={`/profile/${aId}`}>
                      <img
                        height="40px"
                        width="40px"
                        className="ml-10"
                        src={
                          selectedMusic?.thumbnail
                            ? selectedMusic?.thumbnail
                            : musicImage
                            ? musicImage
                            : selectedMusic?.musicImage
                            ? selectedMusic?.musicImage
                            : null
                        }
                        alt="song"
                        style={{
                          zIndex: "1",
                          alignSelf: "center",
                          borderRadius: "10px",
                        }}
                        onMouseOver={() => {
                          setAid(
                            location.pathname === "/"
                              ? selectedMusic.artistData?._id
                              : selectedMusic?.playlistData?.Songs[0]?.artistId
                                  ._id
                          );
                        }}
                        onClick={() => {
                          console.log(
                            "hover--id",
                            selectedMusic.artistData?._id
                          );
                          setAid(
                            location.pathname === "/"
                              ? selectedMusic.artistData?._id
                              : selectedMusic.playlistData.Songs[0].artistId._id
                          );
                          navigate(
                            `/profile/${
                              location.pathname === "/"
                                ? selectedMusic.artistData?._id
                                : selectedMusic.playlistData.Songs[0].artistId
                                    ._id
                            }`
                          );
                        }}
                        OnTouchStart={() => {
                          console.log(
                            "hover--id",
                            selectedMusic.artistData?._id
                          );
                          setAid(
                            location.pathname === "/"
                              ? selectedMusic.artistData?._id
                              : selectedMusic.playlistData.Songs[0].artistId._id
                          );
                          navigate(
                            `/profile/${
                              location.pathname === "/"
                                ? selectedMusic.artistData?._id
                                : selectedMusic.playlistData.Songs[0].artistId
                                    ._id
                            }`
                          );
                        }}
                      />
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                {isPlaying ? (
                  <marquee behavior="scroll" direction="left" width="300">
                    <p
                      className="font-size-18 tracking-normal font-medium white-text-color mb-0"
                      onClick={() => {
                        goToPreviousPath();
                      }}
                      OnTouchStart={() => {
                        goToPreviousPath();
                      }}
                    >
                      <span>
                        {selectedMusic?.artistData?.artistName
                          ? selectedMusic?.artistData?.artistName
                          : artistName || null}{" "}
                        {/* {selectedMusic?.artistData?.realName
                          ? selectedMusic?.artistData?.realName
                          : realName || null}{" "} */}
                      </span>

                      <strong>
                        {selectedMusic?.title
                          ? selectedMusic?.title
                          : title
                          ? title
                          : selectedMusic?.musicTitle
                          ? selectedMusic?.musicTitle
                          : null}
                      </strong>
                    </p>
                  </marquee>
                ) : (
                  <div style={{ width: "300px", textAlign: "center" }}>
                    <p className="font-size-18 tracking-normal font-medium white-text-color mb-0 ">
                      {selectedMusic?.artistData?.artistName
                        ? selectedMusic?.artistData?.artistName
                        : artistName || null}{" "}
                      {/* {selectedMusic?.artistData?.realName
                        ? selectedMusic?.artistData?.realName
                        : realName || null}{" "} */}
                      <strong>
                        {selectedMusic?.title
                          ? selectedMusic?.title
                          : title
                          ? title
                          : selectedMusic?.musicTitle
                          ? selectedMusic?.musicTitle
                          : null}
                      </strong>
                    </p>{" "}
                  </div>
                )}
              </div>
            </div>
            <div className="lg:w-1/3 pl-3 pr-3 m-pl-0 m-pr-0">
              <div className="flex items-center justify-end m-center">
                <div className="flex items-center">
                  <div className="footer-align-space">
                    <p
                      className="text-white flex items-left justify-end h7 text-xs p-0"
                      style={{ minHeight: "15px", paddingRight: "1rem" }}
                    >
                      {/* {formatDuration(trackProgress)} /{" "}
                      {formatDuration(duration)} */}
                    </p>
                  </div>
                  <div
                    className="pr-8 cursor-pointer"
                    onClick={() => {
                      handleLoop();
                    }}
                    OnTouchStart={() => {
                      handleLoop();
                    }}
                  >
                    {onLoop ? (
                      <img
                        className="h-4"
                        src={require("../../Assets/Images/svgon.svg").default}
                        onClick={() => setOnLoop(true)}
                        OnTouchStart={() => setOnLoop(true)}
                        alt=""
                      />
                    ) : (
                      <img
                        className="h-5"
                        src={require("../../Assets/Images/repet.svg").default}
                        onClick={() => setOnLoop(false)}
                        OnTouchStart={() => setOnLoop(false)}
                        alt="'"
                      />
                    )}
                    {/* <img
                      height="18"
                      width="18"
                      src={require("../../Assets/Images/repet.svg").default}
                    /> */}
                  </div>
                  <div>
                    <div className="flex items-center">
                      <div>
                        {isMuted ? (
                          <img
                            height="25"
                            width="25"
                            src={
                              require("../../Assets/Images/sound-mute.png")
                                .default
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setAudioVolumeMute();
                            }}
                            OnTouchStart={(e) => {
                              e.preventDefault();
                              setAudioVolumeMute();
                            }}
                            alt=""
                          />
                        ) : (
                          <img
                            className="h-5"
                            src={
                              require("../../Assets/Images/sound.svg").default
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setAudioVolumeMute();
                            }}
                            OnTouchStart={(e) => {
                              e.preventDefault();
                              setAudioVolumeMute();
                            }}
                            height="25"
                            width="25"
                            alt=""
                          />
                        )}
                      </div>
                      <div className="pl-3 pb-2 volume-bar">
                        <input
                          type="range"
                          value={volume}
                          step="0.01"
                          min="0"
                          max="1"
                          onChange={(e) => {
                            e.preventDefault();
                            onScrubVolume(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
