/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import { useEffect } from "react";

function useOnClickOutside(ref1, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref1.current || ref1.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener( "TouchStart", listener);
    return () => {
      // document.removeEventListener("mousedown", listener);
      document.removeEventListener( "TouchStart", listener);
    };
  }, [ref1, handler]);
}
export default useOnClickOutside;
