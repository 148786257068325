/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import { MUSIC_DATA_INSERT } from "./actionType";
const initialStore = {};

const MusicData = (state = initialStore, action) => {
  switch (action.type) {
    case MUSIC_DATA_INSERT:
      return { ...action.payload };
    default:
      return state;
  }
};

export default MusicData;
