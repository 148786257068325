/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
// import debounce from 'lodash.debounce'; // this is to avoid the press and hold issues we had on some pages on 
import FastClick from 'fastclick';



// import { createRoot } from "react-dom/client";
// import { hydrateRoot } from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
