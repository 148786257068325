/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "../../js/Trending/trending.scss";
import "../../../Components/styles/dashboard.scss";
import Sidebar from "../../Layout/Sidebar";
import Header from "../../Layout/Header";
import { NavLink, useNavigate } from "react-router-dom";
import Footer from "../../Layout/Footer";
import { Dropdown } from "react-bootstrap";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import * as authUtil from "../../../utils/auth.util";
import * as userUtil from "../../../utils/user.util";
import Menu from "../../../Assets/Images/dots.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Payments from "../Payments/Payments";
import LoadingBalls from "../../../Assets/LoadingBalls";
import { useSelector, useDispatch } from "react-redux";
import { getTrending } from "../../../store/reducers/AllTrending/action";
import { getMusicData, getFreeMusicData } from "../../../store/reducers/MusicData/action";
import { Tooltip } from "@material-ui/core";
import Country from "../countries/Country";
import * as userUtils from "../../../utils/user.util";
import Userlogin from "../Login/Login";
import UserSignup from "../Login/Signup";
import Forgotpassword from "../Login/Forgotpassword";
import useOnClickOutside from "../../../helpers/outsideClick";
import debounce from 'lodash.debounce'; // this is to avoid the press and hold issues we had on some pages on MOBILE
import FastClick from 'fastclick';  // to avoid delay on any action




const stripePromise = loadStripe(`${process.env.REACT_APP_PK_LIVE}`);
export default function Trending() {
  const [loading, setLoading] = useState(false);
  const [dId, setDownloadId] = useState();
  const [forgotModal, setForgotModal] = useState(false);

  const userId = userUtils.getUserInfo()?.user?.id;

  const [allPlaylist, setAllPlaylist] = useState([]);
  const [Idc, setIdc] = useState();
  const [showModal, setShowModal] = useState({
    open: false,
    musicId: null,
    amount: null,
    musicTitle: null,
    artistId: null,
  });
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [selectionBox, setSelectionBox] = useState(true);
  const [formBox, setFormBox] = useState(false);
  const [playlistModal, setPlaylistModal] = useState({
    open: false,
    musicId: null,
  });
  const [errors, setErrors] = useState([]);
  const [signupErrors, setSignupErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [signupFormData, setSignupFormData] = useState({});
  const [countryInit, setCountryInit] = useState();
  const [passwordSeen, setPasswordSeen] = useState(false);
  const handlePasswordSeen = () => {
    setPasswordSeen(!passwordSeen);
  };

  const history = useNavigate();
  const validate = () => {
    let error = [];
    let formIsValid = true;

    if (!formData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    }
    if (!formData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }
    setErrors(error);
    return formIsValid;
  };

  const signupValidate = () => {
    let error = [];
    let formIsValid = true;

    if (!signupFormData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(signupFormData.email)
    ) {
      formIsValid = false;
      error["email"] = "Please Enter proper Email";
    }
    if (!signupFormData.contact) {
      formIsValid = false;
      error["contact"] = "Please Enter Contact";
    } else if (!/[0-9]/.test(signupFormData.contact)) {
      formIsValid = false;
      error["contact"] = "Please enter proper Contact";
    }
    if (!signupFormData.firstName) {
      formIsValid = false;
      error["firstName"] = "Please Enter FirstName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.firstName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.lastName) {
      formIsValid = false;
      error["lastName"] = "Please Enter LastName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.lastName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }

    setSignupErrors(error);
    return formIsValid;
  };
  const handleSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (validate()) {
        const data = {
          email: formData.email,
          password: formData.password,
        };
        setLoading(true);
        await ApiPost("users/login", data)
          .then((res) => {
            if (res.data.message === "Successfully logged in.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setLoginModal(false);
              window.location.reload();
            } else if (res.msg) {
              setLoading(false);
              setErrors({ user: "User does not exist." });
            } else {
              setLoading(false);
              setErrors({ user: "Please check username or password" });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };

  const handleSignupSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (signupValidate()) {
        const data = {
          firstName: signupFormData.firstName,
          lastName: signupFormData.lastName,
          email: signupFormData.email,
          password: signupFormData.password,
          contact: signupFormData.contact,
          country: countryInit,
          countryCode: Idc,
        };
        setLoading(true);
        let err = [];

        await ApiPost("users/signup", data)
          .then((res) => {
            if (res?.data?.data === "Email has been already registered.") {
              err["error"] = res.data.data;
              setSignupErrors(err);
            }
            if (res.data.message === "Record Successfully Inserted.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setSignupModal(false);
              window.location.reload();
            } else {
              setLoading(false);
              setErrors({ user: res.msg });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSignupChange = (e) => {
    let { name, value } = e.target;

    setSignupFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const allTrending = useSelector((state) => state.AllTrending);
  const dispatch = useDispatch();

  useEffect(() => {
    if (allTrending?.length === 0 || 5) {
      getAllTrending();
    }
    getAllPlaylist();
  }, []);
  const getAllPlaylist = async () => {
    await ApiGet("playlist/get-by-user")
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          setAllPlaylist(res.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleAddToPlaylist = async (id) => {
    setLoading(true);
    const data = {
      playlistId: id,
      musicId: playlistModal.musicId,
    };
    await ApiPost("playlist/add-to-playlist", data)
      .then((res) => {
        setLoading(false);
        console.log("response", res);
        if ((res.data.message = "record already exists")) {
          toast.error("Song is already added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setPlaylistModal({ open: false });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const getAllTrending = async () => {
    await ApiGet(`music/get-treanding-music/${null}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getTrending(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadMusic = async (id) => {
    console.log("Downloading");

    await ApiGet(`music/download?musicId=${id}`)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSendEmail = async (id) => {
    const bodyId = {
      musicId: id,
      userId: userId,
    };
    await ApiPost(`downloadUser/downloadUsers`, bodyId)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handledownload = async (data) => {
    let url = data.musicUrl;
    let title = data?.musicTitle ? data?.musicTitle : data.title;
    // console.log(e.target.href);
    await axios({
      url: url,
      method: "GET",
      responseType: "blob",
    }).then(async (response) => {
      toast.success("Your download will begin shortly");
      handleSendEmail(data._id);
      console.log("object");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${title}.mp3`); //or any other extension
      document.body.appendChild(link);
      downloadUserList(data);
      await downloadMusic(data._id);
      link.click();
    });
  };

  const downloadUserList = async (data) => {
    const body = {
      musicId: data._id,
      userId: userId,
      artistId: data.artistData._id,
    };

    await ApiPost(
      `musicDownloadListForArtist/create-musicDownloadListForArtist`,
      body
    )
      .then((res) => {
        console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxx", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const [open, setOpen] = useState({});
  const NavigatorRef2 = useRef();

  useOnClickOutside(NavigatorRef2, () => {
    setOpen({});
  });

  // play all free songs on a list one by one from page to page 
  const handlePlaySong = (record, key) => {
    if (record.isPaid) {
       if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
       } else {
        setLoginModal(true);
       }
    } else {
      dispatch(getMusicData({playlistData: allTrending, index: key}));
    }
  };

  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box mobile-Box">
            <div className="container-fluid">
              <div className="md:flex pt-24  pt-24-mobile">
                <div className="md:w-full">
                  <div className="pt-5 flex items-center pt-5-mobile">
                    <NavLink to="/" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}>
                      <p>
                        <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                      </p>
                    </NavLink>
                    <p className="pl-3 adobe-font font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                      Trending
                    </p>
                  </div>
                </div>
              </div>
              {!allTrending?.length && (
                <div className="dots mt-10">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              )}
              <div className="pt-4 cus-grid">
                {allTrending?.length
                  ? allTrending
                      ?.slice(0)
                      //?.reverse()
                      ?.map((record, key) => {
                        return (
                          <div className="grid-items">
                            <div className="singer-box">
                              <div
                                className="singer-img"
                                onClick={() => 
                                  handlePlaySong(record, key)         // play all free songs on a list one by one from page to page 

                                }
                                OnTouchStart={() => 
                                  handlePlaySong(record, key)}      // play all free songs on a list one by one from page to page 
 
                              >
                                <img src={record && record.thumbnail}  alt=""/>
                              </div>
                              <div className="singer-text p-5 mini-view-p-remove">
                                <div className="flex items-center justify-between">
                                  <div>
                                    <p
                                      className="font-size-18 white-text-color font-normal tracking-normal mb-0 cursor-pointer "
                                      onClick={() =>
                                        handlePlaySong(record, key)
                                      }
                                      OnTouchStart={() =>
                                        handlePlaySong(record, key)
                                      }
                                    >
                                      {record.title}
                                    </p>
                                    <p
                                      className="font-size-18 gray-text-color font-normal tracking-normal mb-0 cursor-pointer hover:text-white"
                                      onClick={() => {
                                        history(
                                          `/profile/${record.artistData?._id}`
                                        );
                                      }}
                                      OnTouchStart={() => {
                                        history(
                                          `/profile/${record.artistData?._id}`
                                        );
                                      }}
                                    >
                                      {
                                        record.artistData?.artistName
                                        // + " " +
                                        //   record.artistData.realName
                                      }{" "}
                                    </p>
                                  </div>
                                  {(() => {
                                    if (authUtil.isLoggedIn()) {
                                      return record.isPaid ? (
                                        userUtil
                                          ?.getMusicData()
                                          ?.includes(record._id) ? (
                                          <>
                                            <div
                                              className="menu_dd"
                                              ref={NavigatorRef2}
                                            >
                                              <div
                                                className="menu-img"
                                                onClick={() => {
                                                  setOpen((pre) => {
                                                    return {
                                                      ...pre,
                                                      [`newrel${record._id}`]:
                                                        !pre[
                                                          `newrel${record._id}`
                                                        ],
                                                    };
                                                  });
                                                }}
                                                OnTouchStart={() => {
                                                  setOpen((pre) => {
                                                    return {
                                                      ...pre,
                                                      [`newrel${record._id}`]:
                                                        !pre[
                                                          `newrel${record._id}`
                                                        ],
                                                    };
                                                  });
                                                }}
                                              >
                                                <img
                                                  src={Menu}
                                                  style={{ height: "25px" }}
                                                  alt=""
                                                />
                                              </div>
                                              {open[`newrel${record._id}`] && (
                                                <div
                                                  className="menu-box"
                                                  // ref={NavigatorRef1}
                                                >
                                                  <div
                                                    className="text-white p-2"
                                                    onClick={() =>
                                                      authUtil.isLoggedIn()
                                                        ? setPlaylistModal({
                                                            musicId: record._id,
                                                            open: true,
                                                          })
                                                        : setLoginModal(true)
                                                    }
                                                    OnTouchStart={() =>
                                                      authUtil.isLoggedIn()
                                                        ? setPlaylistModal({
                                                            musicId: record._id,
                                                            open: true,
                                                          })
                                                        : setLoginModal(true)
                                                    }
                                                  >
                                                    <button>
                                                      Add to playlist
                                                    </button>
                                                  </div>

                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() =>
                                                      dispatch(
                                                        getMusicData(record, key)
                                                      )
                                                    }
                                                    OnTouchStart={() =>
                                                      dispatch(
                                                        getMusicData(record, key)
                                                      )
                                                    }
                                                  >
                                                    <button>
                                                      Play song now
                                                    </button>
                                                  </div>
                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() =>
                                                      history(
                                                        `/song/${record._id}`
                                                      )
                                                    }
                                                    OnTouchStart={() =>
                                                      history(
                                                        `/song/${record._id}`
                                                      )
                                                    }
                                                  >
                                                    <button>Details</button>
                                                  </div>
                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() => {
                                                      setDownloadId(record._id);
                                                      authUtil.isLoggedIn()
                                                        ? handledownload(record, key)
                                                        : setLoginModal(true);
                                                    }}
                                                    OnTouchStart={() => {
                                                      setDownloadId(record._id);
                                                      authUtil.isLoggedIn()
                                                        ? handledownload(record, key)
                                                        : setLoginModal(true);
                                                    }}
                                                  >
                                                    <button>Download</button>
                                                  </div>
                                                  <div className="text-white p-2 cursor-pointer">
                                                    <button>Share</button>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                            
                                          </>
                                        ) : (
                                          <div
                                          onClick={() => 
                                            handlePlaySong(record, key)         // play all free songs on a list one by one from page to page 
          
                                          }
                                          OnTouchStart={() => 
                                            handlePlaySong(record, key)}      // play all free songs on a list one by one from page to page 
           
                                          >
                                            <i className="fas fa-crown yellow-icon-color cursor-pointer font-size-18"></i>
                                          </div>
                                        )
                                      ) : (
                                        <>
                                          <div
                                            className="menu_dd"
                                            ref={NavigatorRef2}
                                          >
                                            <div
                                              className="menu-img"
                                              onClick={() => {
                                                setOpen((pre) => {
                                                  return {
                                                    ...pre,
                                                    [`newrel${record._id}`]:
                                                      !pre[
                                                        `newrel${record._id}`
                                                      ],
                                                  };
                                                });
                                              }}
                                            >
                                              <img
                                                src={Menu}
                                                style={{ height: "25px" }}
                                                alt=""
                                              />
                                            </div>
                                            {open[`newrel${record._id}`] && (
                                              <div
                                                className="menu-box"
                                                // ref={NavigatorRef1}
                                              >
                                                <div
                                                  className="text-white p-2 "
                                                  onClick={() =>
                                                    authUtil.isLoggedIn()
                                                      ? setPlaylistModal({
                                                          musicId: record._id,
                                                          open: true,
                                                        })
                                                      : setLoginModal(true)
                                                  }
                                                  OnTouchStart={() =>
                                                    authUtil.isLoggedIn()
                                                      ? setPlaylistModal({
                                                          musicId: record._id,
                                                          open: true,
                                                        })
                                                      : setLoginModal(true)
                                                  }
                                                >
                                                  <button>
                                                    Add to playlist
                                                  </button>
                                                </div>

                                                <div
                                                  className="text-white p-2 cursor-pointer"
                                                  onClick={() =>
                                                    dispatch(
                                                      getMusicData(record, key)
                                                    )
                                                  }
                                                  OnTouchStart={() =>
                                                    dispatch(
                                                      getMusicData(record, key)
                                                    )
                                                  }
                                                >
                                                  <button>Play song now</button>
                                                </div>
                                                <div
                                                  className="text-white p-2 cursor-pointer"
                                                  onClick={() =>
                                                    history(
                                                      `/song/${record._id}`
                                                    )
                                                  }
                                                  OnTouchStart={() =>
                                                    history(
                                                      `/song/${record._id}`
                                                    )
                                                  }
                                                >
                                                  <button>Details</button>
                                                </div>
                                                <div
                                                  className="text-white p-2"
                                                  onClick={() => {
                                                    setDownloadId(record._id);
                                                    authUtil.isLoggedIn()
                                                      ? handledownload(record, key)
                                                      : setLoginModal(true);
                                                  }}
                                                  OnTouchStart={() => {
                                                    setDownloadId(record._id);
                                                    authUtil.isLoggedIn()
                                                      ? handledownload(record, key)
                                                      : setLoginModal(true);
                                                  }}
                                                >
                                                  <button>Download</button>
                                                </div>
                                                <div className="text-white p-2">
                                                  <button>Share</button>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                          {/* <Dropdown>
                                            
                                          </Dropdown> */}
                                        </>
                                      );
                                    } else {
                                      return record.isPaid ? (
                                        <div
                                        onClick={() => 
                                          handlePlaySong(record, key)         // play all free songs on a list one by one from page to page 
        
                                        }
                                        OnTouchStart={() => 
                                          handlePlaySong(record, key)}      // play all free songs on a list one by one from page to page 
         
                                        >
                                          <i className="fas fa-crown yellow-icon-color cursor-pointer font-size-18"></i>
                                        </div>
                                      ) : (
                                        <>
                                          <div
                                            className="menu_dd"
                                            ref={NavigatorRef2}
                                          >
                                            <div
                                              className="menu-img"
                                              onClick={() => {
                                                setOpen((pre) => {
                                                  return {
                                                    ...pre,
                                                    [`newrel${record._id}`]:
                                                      !pre[
                                                        `newrel${record._id}`
                                                      ],
                                                  };
                                                });
                                              }}
                                            >
                                              <img
                                                src={Menu}
                                                style={{ height: "25px" }}
                                                alt=""
                                              />
                                            </div>
                                            {open[`newrel${record._id}`] && (
                                              <div
                                                className="menu-box"
                                                // ref={NavigatorRef1}
                                              >
                                                <div
                                                  className="text-white p-2"
                                                  onClick={() =>
                                                    authUtil.isLoggedIn()
                                                      ? setPlaylistModal({
                                                          musicId: record._id,
                                                          open: true,
                                                        })
                                                      : setLoginModal(true)
                                                  }
                                                  OnTouchStart={() =>
                                                    authUtil.isLoggedIn()
                                                      ? setPlaylistModal({
                                                          musicId: record._id,
                                                          open: true,
                                                        })
                                                      : setLoginModal(true)
                                                  }
                                                >
                                                  Add to playlist
                                                </div>

                                                <div
                                                  className="text-white p-2"
                                                  onClick={() =>
                                                    dispatch(
                                                      getMusicData(record, key)
                                                    )
                                                  }
                                                  OnTouchStart={() =>
                                                    dispatch(
                                                      getMusicData(record, key)
                                                    )
                                                  }
                                                >
                                                  Play song now
                                                </div>
                                                <div
                                                  className="text-white p-2 cursor-pointer"
                                                  onClick={() =>
                                                    history(
                                                      `/song/${record._id}`
                                                    )
                                                  }
                                                  OnTouchStart={() =>
                                                    history(
                                                      `/song/${record._id}`
                                                    )
                                                  }
                                                >
                                                  Details
                                                </div>
                                                <div
                                                  className="text-white p-2"
                                                  onClick={() => {
                                                    setDownloadId(record._id);
                                                    authUtil.isLoggedIn()
                                                      ? handledownload(record, key)
                                                      : setLoginModal(true);
                                                  }}
                                                  OnTouchStart={() => {
                                                    setDownloadId(record._id);
                                                    authUtil.isLoggedIn()
                                                      ? handledownload(record, key)
                                                      : setLoginModal(true);
                                                  }}
                                                >
                                                  Download
                                                </div>
                                                <div className="text-white p-2">
                                                  Share
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                          {/* <Dropdown>
                                            <Dropdown.Toggle
                                              variant="success"
                                              id="dropdown-basic"
                                              className="text-white"
                                            >
                                              <Tooltip title="More" arrow>
                                                <i className="fas fa-ellipsis-v cursor-pointer font-size-10 text-white p-3"></i>
                                              </Tooltip>{" "}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <div
                                                className="flex flex-col w-32 max-w-full border-color box-shadow-cus "
                                                style={{
                                                  backgroundColor: "#090C10",
                                                }}
                                              >
                                                <div
                                                  className="text-white p-2"
                                                  onClick={() =>
                                                    authUtil.isLoggedIn()
                                                      ? setPlaylistModal({
                                                          musicId: record._id,
                                                          open: true,
                                                        })
                                                      : setLoginModal(true)
                                                  }
                                                >
                                                  Add to playlist
                                                </div>

                                                <div
                                                  className="text-white p-2"
                                                  onClick={() =>
                                                    dispatch(
                                                      getMusicData(record)
                                                    )
                                                  }
                                                >
                                                  Play song now
                                                </div>
                                                <div
                                                  className="text-white p-2"
                                                  onClick={() =>
                                                    history(
                                                      `/song/${record._id}`
                                                    )
                                                  }
                                                >
                                                  Details
                                                </div>
                                                <div
                                                  className="text-white p-2"
                                                  onClick={() => {
                                                    setDownloadId(record._id);
                                                    authUtil.isLoggedIn()
                                                      ? handledownload(record)
                                                      : setLoginModal(true);
                                                  }}
                                                >
                                                  Download
                                                </div>
                                                <div className="text-white p-2">
                                                  Share
                                                </div>
                                              </div>
                                            </Dropdown.Menu>
                                          </Dropdown> */}
                                        </>
                                      );
                                    }
                                  })()}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                  : ""}
              </div>
              {/* //* Playlist modal */}
              {playlistModal.open ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="playlist border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div
                          className="justify-content-center p-5 relative  border-solid border-white-200 rounded-t"
                          // style={{
                          //   backgroundColor: "#090C10",
                          // }}
                        >
                          <h3 className="text-2xl text-center font-bold text-white">
                            Select Playlist
                          </h3>

                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                          >
                            <p className="button-center-modal">
                              <i className="fas fa-times"></i>
                            </p>
                          </button>
                        </div>
                        {/*body*/}
                        <div
                          className="relative p-6 flex-auto"
                          // style={{
                          //   backgroundColor: "#090C10",
                          // }}
                        >
                          <p className="my-4 text-white text-lg leading-relaxed">
                            <div
                              className="flex items-center flex-row flex-wrap pt-4"
                              style={{ height: "450px", overflowY: "overlay" }}
                            >
                              {allPlaylist && allPlaylist.length ? (
                                allPlaylist.map((record, index) => {
                                  return (
                                    <div
                                      className="pl-3 pr-3 cursor-pointer "
                                      key={index}
                                      onClick={() => {
                                        handleAddToPlaylist(record._id);
                                      }}
                                      OnTouchStart={() => {
                                        handleAddToPlaylist(record._id);
                                      }}
                                    >
                                      <div className="singer-box">
                                        <div className="singer-img">
                                          <img
                                            src={
                                              record &&
                                              record.Songs &&
                                              record.Songs.length &&
                                              record.Songs[0].musicImage
                                            }
                                            style={{
                                              height: "150px",
                                              width: "335px",
                                            }}
                                            alt=""
                                          />
                                        </div>
                                        <div className="singer-text p-4">
                                          <div className="flex items-center justify-between">
                                            <div>
                                              <p className="font-size-14 white-text-color font-normal tracking-normal mb-0">
                                                {record.playlist_name}
                                              </p>
                                            </div>
                                            <div>
                                              {record?.Songs?.map((rec) => {
                                                if (
                                                  rec._id ===
                                                  playlistModal.musicId
                                                ) {
                                                  return (
                                                    <>
                                                      <p className="cursor-pointer font-size-14 login-text-color">
                                                        added
                                                        <i className="pl-1 fas fa-check cursor-pointer font-size-14 login-text-color"></i>
                                                      </p>
                                                    </>
                                                  );
                                                }
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="dots">
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                </div>
                              )}
                            </div>
                          </p>
                        </div>

                        <div
                          className="flex items-center justify-end p-4 border-solid border-white rounded-b"
                          style={{ backgroundColor: "#090C10" }}
                        >
                          <button
                            className="text-red-500 border-solid border-white font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}

              {/* //* Purchase modal */}
              {showModal.open ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="poppop border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div
                          className="flex items-start justify-between pt-5 pr-5 pl-5 border-solid border-white-100 rounded-t-lg"
                          // style={{
                          //   backgroundColor: "#0B0B0B",
                          // }}
                        >
                          <h3 className="text-2xl font-bold text-white">
                            Buy this song or album
                          </h3>
                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() =>
                              setShowModal({
                                ...showModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setShowModal({
                                ...showModal,
                                open: false,
                              })
                            }
                          >
                            <p>
                              <i className="fas fa-times"></i>
                            </p>
                          </button>
                        </div>
                        {/*body*/}
                        <div
                          className="relative p-6 flex-auto rounded-b-lg"
                          // style={{ backgroundColor: "#090C10" }}
                        >
                          <p className="mb-3 text-white text-lg leading-relaxed">
                            Like this artists music? Show your support by
                            purchasing their music.
                          </p>
                          <p className="mb-3 text-white text-lg leading-relaxed">
                            Title: {showModal.musicTitle}
                          </p>
                          <p className="mb-3 text-white text-lg leading-relaxed">
                            Price: ${showModal.amount}
                          </p>
                          <Elements stripe={stripePromise}>
                            <Payments
                              musicId={showModal.musicId}
                              amount={showModal.amount}
                              musicTitle={showModal.musicTitle}
                              musicArtistId={showModal.artistId}
                            />
                          </Elements>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}
              {forgotModal ? (
                <Forgotpassword
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}

              {loginModal ? (
                <Userlogin
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}

              {signupModal ? (
                <UserSignup
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}
            </div>
          </div>
          {loading && <LoadingBalls />}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            closeOnTouchStart
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
          />
          <div></div> 
        </main>
      </div>
    </div>
  );
}
