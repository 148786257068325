/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import "../News/News.scss";

export default function Heading() {
  const history = useNavigate();
  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="md:flex">
              <div className="md:w-full">
                <div className="pt-5">
                  <p className="font-size-35 white-text-color font-medium tracking-normal text-center">
                    Heading
                  </p>
                </div>
              </div>
            </div>
            <div className="md:flex md:flex-wrap pt-5">
              <div className="md:w-1/3 pl-3 pr-3 mb-5">
                <NavLink to="/headingchild" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}>
                  <div className="heading-main-box">
                    <div>
                      <p className="font-size-16 white-text-color font-medium tracking-normal mb-0">
                        project and event name
                      </p>
                    </div>
                    <div className="heading-profile-img pt-3">
                      <img
                        src={
                          require("../../../Assets/Images/heading.png").default
                        }alt=""
                      />
                    </div>
                    <div className="heading-text">
                      <p className="font-size-16 white-text-color font-normal tracking-normal mb-0 mt-3">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat , sed diam voluptua. At vero
                        eos et accusam justo duo dolores et ea rebum. Stet clita
                        kasd gubergren , no sea takimata sanctus est Lorem ipsum
                        dolor sit amet . Lorem ipsum dolor sit , no sea
                        takimata…
                        <span className="pl-2 link-text-color cursor-pointer">
                          Read more
                        </span>
                      </p>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className="md:w-1/3 pl-3 pr-3 mb-5">
                <div className="heading-main-box">
                  <div>
                    <p className="font-size-16 white-text-color font-medium tracking-normal mb-0">
                      project and event name
                    </p>
                  </div>
                  <div className="heading-profile-img pt-3">
                    <img
                      src={
                        require("../../../Assets/Images/heading.png").default
                      } alt=""
                    />
                  </div>
                  <div className="heading-text">
                    <p className="font-size-16 white-text-color font-normal tracking-normal mb-0 mt-3">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat , sed diam voluptua. At vero eos et
                      accusam justo duo dolores et ea rebum. Stet clita kasd
                      gubergren , no sea takimata sanctus est Lorem ipsum dolor
                      sit amet . Lorem ipsum dolor sit , no sea takimata…
                      <span className="pl-2 link-text-color cursor-pointer">
                        Read more
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:w-1/3 pl-3 pr-3 mb-5">
                <div className="heading-main-box">
                  <div>
                    <p className="font-size-16 white-text-color font-medium tracking-normal mb-0">
                      project and event name
                    </p>
                  </div>
                  <div className="heading-profile-img pt-3">
                    <img
                      src={
                        require("../../../Assets/Images/heading.png").default
                      } alt=""
                    />
                  </div>
                  <div className="heading-text">
                    <p className="font-size-16 white-text-color font-normal tracking-normal mb-0 mt-3">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat , sed diam voluptua. At vero eos et
                      accusam justo duo dolores et ea rebum. Stet clita kasd
                      gubergren , no sea takimata sanctus est Lorem ipsum dolor
                      sit amet . Lorem ipsum dolor sit , no sea takimata…
                      <span className="pl-2 link-text-color cursor-pointer">
                        Read more
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:w-1/3 pl-3 pr-3 mb-5">
                <div className="heading-main-box">
                  <div>
                    <p className="font-size-16 white-text-color font-medium tracking-normal mb-0">
                      project and event name
                    </p>
                  </div>
                  <div className="heading-profile-img pt-3">
                    <img
                      src={
                        require("../../../Assets/Images/heading.png").default
                      } alt=""
                    />
                  </div>
                  <div className="heading-text">
                    <p className="font-size-16 white-text-color font-normal tracking-normal mb-0 mt-3">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat , sed diam voluptua. At vero eos et
                      accusam justo duo dolores et ea rebum. Stet clita kasd
                      gubergren , no sea takimata sanctus est Lorem ipsum dolor
                      sit amet . Lorem ipsum dolor sit , no sea takimata…
                      <span className="pl-2 link-text-color cursor-pointer">
                        Read more
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:w-1/3 pl-3 pr-3 mb-5">
                <div className="heading-main-box">
                  <div>
                    <p className="font-size-16 white-text-color font-medium tracking-normal mb-0">
                      project and event name
                    </p>
                  </div>
                  <div className="heading-profile-img pt-3">
                    <img
                      src={
                        require("../../../Assets/Images/heading.png").default
                      } alt=""
                    />
                  </div>
                  <div className="heading-text">
                    <p className="font-size-16 white-text-color font-normal tracking-normal mb-0 mt-3">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat , sed diam voluptua. At vero eos et
                      accusam justo duo dolores et ea rebum. Stet clita kasd
                      gubergren , no sea takimata sanctus est Lorem ipsum dolor
                      sit amet . Lorem ipsum dolor sit , no sea takimata…
                      <span className="pl-2 link-text-color cursor-pointer">
                        Read more
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:w-1/3 pl-3 pr-3 mb-5">
                <div className="heading-main-box">
                  <div>
                    <p className="font-size-16 white-text-color font-medium tracking-normal mb-0">
                      project and event name
                    </p>
                  </div>
                  <div className="heading-profile-img pt-3">
                    <img
                      src={
                        require("../../../Assets/Images/heading.png").default
                      } alt=""
                    />
                  </div>
                  <div className="heading-text">
                    <p className="font-size-16 white-text-color font-normal tracking-normal mb-0 mt-3">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat , sed diam voluptua. At vero eos et
                      accusam justo duo dolores et ea rebum. Stet clita kasd
                      gubergren , no sea takimata sanctus est Lorem ipsum dolor
                      sit amet . Lorem ipsum dolor sit , no sea takimata…
                      <span className="pl-2 link-text-color cursor-pointer">
                        Read more
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:w-1/3 pl-3 pr-3 mb-5">
                <div className="heading-main-box">
                  <div>
                    <p className="font-size-16 white-text-color font-medium tracking-normal mb-0">
                      project and event name
                    </p>
                  </div>
                  <div className="heading-profile-img pt-3">
                    <img
                      src={
                        require("../../../Assets/Images/heading.png").default
                      }alt=""
                    />
                  </div>
                  <div className="heading-text">
                    <p className="font-size-16 white-text-color font-normal tracking-normal mb-0 mt-3">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat , sed diam voluptua. At vero eos et
                      accusam justo duo dolores et ea rebum. Stet clita kasd
                      gubergren , no sea takimata sanctus est Lorem ipsum dolor
                      sit amet . Lorem ipsum dolor sit , no sea takimata…
                      <span className="pl-2 link-text-color cursor-pointer">
                        Read more
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:w-1/3 pl-3 pr-3 mb-5">
                <div className="heading-main-box">
                  <div>
                    <p className="font-size-16 white-text-color font-medium tracking-normal mb-0">
                      project and event name
                    </p>
                  </div>
                  <div className="heading-profile-img pt-3">
                    <img
                      src={
                        require("../../../Assets/Images/heading.png").default
                      }alt=""
                    />
                  </div>
                  <div className="heading-text">
                    <p className="font-size-16 white-text-color font-normal tracking-normal mb-0 mt-3">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat , sed diam voluptua. At vero eos et
                      accusam justo duo dolores et ea rebum. Stet clita kasd
                      gubergren , no sea takimata sanctus est Lorem ipsum dolor
                      sit amet . Lorem ipsum dolor sit , no sea takimata…
                      <span className="pl-2 link-text-color cursor-pointer">
                        Read more
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:w-1/3 pl-3 pr-3 mb-5">
                <div className="heading-main-box">
                  <div>
                    <p className="font-size-16 white-text-color font-medium tracking-normal mb-0">
                      project and event name
                    </p>
                  </div>
                  <div className="heading-profile-img pt-3">
                    <img
                      src={
                        require("../../../Assets/Images/heading.png").default
                      }alt=""
                    />
                  </div>
                  <div className="heading-text">
                    <p className="font-size-16 white-text-color font-normal tracking-normal mb-0 mt-3">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat , sed diam voluptua. At vero eos et
                      accusam justo duo dolores et ea rebum. Stet clita kasd
                      gubergren , no sea takimata sanctus est Lorem ipsum dolor
                      sit amet . Lorem ipsum dolor sit , no sea takimata…
                      <span className="pl-2 link-text-color cursor-pointer">
                        Read more
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
