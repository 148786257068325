/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */


import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../js/Playlist/playlist.scss";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import { ApiDelete, ApiGet, ApiPut } from "./../../../helpers/API/ApiData";
import { isLoggedIn } from "../../../utils/auth.util";
import Menu from "../../../Assets/Images/dots.png";
import { useRef } from "react";
import useOnClickOutside from "../../../helpers/outsideClick";
import { Mobile } from "aws-sdk";
import { getMusicData } from "../../../store/reducers/MusicData/action";
import { useDispatch, useSelector } from "react-redux";
import * as authUtil from "../../../utils/auth.util";



export default function PlayList() {
  const history = useNavigate();
  const [allPlaylist, setAllPlayList] = useState([]);
  const [dataChange, setDataChange] = useState([]);
  const [playlistData, setPlaylistData] = useState({});
  const [playlistErrors, setPlaylistErrors] = useState([]);
  const [editPlaylist, setEditPlaylist] = useState({
    open: false,
    playlistId: null,
  });

  useEffect(() => {
    getAllPlaylist();
  }, [dataChange]);
  const [loginModal, setLoginModal] = useState(false);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const getAllPlaylist = async () => {
    await ApiGet("playlist/get-by-user/")
      .then((res) => {
        if (typeof (res.data.data !== "string")) {
          setAllPlayList(res.data.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleDeletePlaylist = async (id) => {
    await ApiDelete(`playlist/${id}`)
      .then((res) => {
        console.log("res", res);
        setDataChange("playlist deleted");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const playlistValidate = () => {
    let error = [];
    let formIsValid = true;

    if (!playlistData.title) {
      formIsValid = false;
      error["details"] = "Please add Title";
    }
    if (!playlistData.isPrivate) {
      formIsValid = false;
      error["details"] = "Please select Privacy";
    }

    setPlaylistErrors(error);
    return formIsValid;
  };

  const handlePlaylistChange = (e) => {
    let { name, value } = e.target;
    setPlaylistData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditPlaylist = async () => {
    if (playlistValidate()) {
      const data = {
        playlistId: editPlaylist.playlistId,
        playlist_name: playlistData.title,
        isPrivate: playlistData.isPrivate,
      };
      console.log("data", data);
      await ApiPut("playlist/update-playlist", data)
        .then((res) => {
          console.log("res", res);
          toast.success("Playlist updated successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDataChange([...dataChange, "Playlist Name Changed"]);
          setEditPlaylist(false);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const handleIsPublic = async (playlistId, isPrivate) => {
    const data = {
      playlistId: playlistId,
      isPrivate: !isPrivate,
    };
    console.log("data", data);
    await ApiPut("playlist/update-playlist", data)
      .then((res) => {
        console.log("res", res);
        toast.success("Playlist updated successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          closeOnTouchStart: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDataChange([...dataChange, "Playlist Privacy Changed"]);
        setEditPlaylist(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const [open, setOpen] = useState({});
  const NavigatorRef2 = useRef();

  useOnClickOutside(NavigatorRef2, () => {
    setOpen({});
  });

  const handleBack = () => {
    history("/");
    window.location.reload();
  };

  // play all free songs on a list one by one from page to page 
  const handlePlayallPlaylist = (record, key) => {
    if (record.isPaid) {
       if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
       } else {
        setLoginModal(true);
       }
    } else {
      dispatch(getMusicData({playlistData: allPlaylist, index: key}));
    }

  return (
    <div style={{ paddingBottom: "6%" }}>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex pt-24  pt-24-mobile">
                <div className="md:w-full pt-4 pl-3 pr-3 pt-5-mobile">
                  <div className="flex items-center ">
                    <div
                      onClick={() => {
                        handleBack();
                      }}
                      OnTouchStart={() => {
                        handleBack();
                      }}
                    >
                      <p>
                        <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                      </p>
                    </div>
                    <p className="pl-3 font-size-25 white-text-color font-bold tracking-normal cursor-pointer">
                      My Playlist
                    </p>
                  </div>
                </div>
              </div>
              {!allPlaylist?.length && isLoggedIn() && <></>}
              {!isLoggedIn() ? (
                <div className="justify-content-center widthFull">
                  <h1 className="text-white text-2xl mt-10">
                    Register To Listen For Free
                  </h1>
                </div>
              ) : (
                <div className="pt-4 cus-grid">
                  {allPlaylist && allPlaylist.length === 0 ? (
                    <div className="justify-content-center widthFull">
                      <h1 className="text-white text-2xl mt-10">
                        No playlists found, Please create one.
                      </h1>
                    </div>
                  ) : allPlaylist && allPlaylist.length ? (
                    allPlaylist?.map((record, index) => {
                      return (
                        <div className=" grid-items" key={index}>
                          <div className="singer-box">
                            <div
                              className="singer-img"
                              onClick={() => {
                                history(`/play/${record._id}`);
                              }}
                              OnTouchStart={() => {
                                history(`/play/${record._id}`);
                              }}
                            >
                              <img
                                src={
                                  record?.Songs[record?.Songs.length - 1]
                                    ?.musicImage ||
                                  "https://cdn.dribbble.com/users/60166/screenshots/5394073/music_logo.jpg"
                                } alt=""
                              />
                            </div>
                            <div className="singer-text p-5">
                              <div className="flex items-center justify-between">
                                <div
                                  // onClick={() => {
                                  //   history(`/play/${record._id, index}`);
                                  // }}
                                  // OnTouchStart={() => {
                                  //   history(`/play/${record._id, index}`);
                                  // }}
                                  className="banner-img"
                                    onClick={() =>
                                      handlePlayallPlaylist(record, index)}    // play all free songs on a list one by one from page to page
                                    
                                    OnTouchStart={() => 
                                      handlePlayallPlaylist(record, index)}
                                >
                                  <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                    {record.playlist_name}
                                  </p>
                                  <p className="font-size-18 gray-text-color font-normal tracking-normal mb-0">
                                    {record.isPrivate ? "Private" : "Public"}
                                  </p>
                                </div>
                                <div>
                                  <div className="menu_dd" ref={NavigatorRef2}>
                                    <div
                                      className="menu-img"
                                      onClick={() => {
                                        setOpen((pre) => {
                                          return {
                                            ...pre,
                                            [`newrel${record._id}`]:
                                              !pre[`newrel${record._id}`],
                                          };
                                        });
                                      }}
                                      OnTouchStart={() => {
                                        setOpen((pre) => {
                                          return {
                                            ...pre,
                                            [`newrel${record._id}`]:
                                              !pre[`newrel${record._id}`],
                                          };
                                        });
                                      }}
                                    >
                                      <img
                                        src={Menu}
                                        style={{ height: "25px" }}
                                        alt=""
                                      />
                                    </div>
                                    {open[`newrel${record._id}`] && (
                                      <div
                                        className="menu-box"
                                        // ref={NavigatorRef1}
                                      >
                                        <div
                                          className="text-white p-2"
                                          onClick={() =>
                                            setEditPlaylist({
                                              playlistId: record._id,
                                              open: true,
                                            })
                                          }
                                          OnTouchStart={() =>
                                            setEditPlaylist({
                                              playlistId: record._id,
                                              open: true,
                                            })
                                          }
                                        >
                                          <Link> Edit playlist</Link>
                                        </div>
                                        <div
                                          className="text-white p-2"
                                          onClick={() =>
                                            handleIsPublic(
                                              record._id,
                                              record.isPrivate
                                            )
                                          }
                                          OnTouchStart={() =>
                                            handleIsPublic(
                                              record._id,
                                              record.isPrivate
                                            )
                                          }
                                        >
                                          <Link>
                                            Make{" "}
                                            {record.isPrivate
                                              ? "Public"
                                              : "Private"}
                                          </Link>
                                        </div>
                                        <div
                                          className="text-white p-2"
                                          onClick={() =>
                                            handleDeletePlaylist(record._id, key)
                                          }
                                          OnTouchStart={() =>
                                            handleDeletePlaylist(record._id, key)
                                          }
                                        >
                                          <Link> Delete playlist</Link>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </div>
          {/* //* Edit Playlist modal */}
          {editPlaylist.open ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*body*/}
                    <div
                      className="px-20 pb-10 pt-5 flex flex-col items-center justify-center rounded-lg"
                      style={{ backgroundColor: "#061E1D" }}
                    >
                      <p className=" px-10 pb-3 font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                        Edit Playlist
                      </p>
                      <span
                        style={{
                          color: "red",
                          top: "0px",
                          fontSize: "15px",
                        }}
                      >
                        {playlistErrors["details"]}
                      </span>
                      <div className="rounded-lg flex flex-col items-center justify-center">
                        <input
                          type="text"
                          style={{
                            backgroundColor: "#3E4E4E",
                            color: "#fff",
                            padding: "10px 20px",
                          }}
                          placeholder="Title"
                          name="title"
                          className="rounded-lg font-size-20"
                          onChange={(e) => handlePlaylistChange(e)}
                        />

                        <div className="flex">
                          <div className="flex items-center justify-center p-4">
                            <input
                              type="radio"
                              name="isPrivate"
                              style={{
                                backgroundColor: "#3E4E4E",
                                color: "#fff",
                              }}
                              className="rounded-lg font-size-20 cursor-pointer"
                              value={true}
                              onChange={(e) => handlePlaylistChange(e)}
                            />
                            <p className="pl-3 text-white font-size-22">
                              Private
                            </p>
                          </div>
                          <div className="flex items-center justify-center p-4">
                            <input
                              type="radio"
                              name="isPrivate"
                              style={{
                                backgroundColor: "#3E4E4E",
                                color: "#fff",
                              }}
                              className="rounded-lg font-size-20 cursor-pointer"
                              value={false}
                              onChange={(e) => handlePlaylistChange(e)}
                            />
                            <p className="pl-3 text-white font-size-22">
                              Public
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <button
                          className="text-red-500 border border-solid border-green rounded font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() =>
                            setEditPlaylist({
                              ...editPlaylist,
                              open: false,
                            })
                          }
                          OnTouchStart={() =>
                            setEditPlaylist({
                              ...editPlaylist,
                              open: false,
                            })
                          }
                        >
                          Cancel
                        </button>
                        <button
                          className="text-green-500 border border-solid border-green rounded font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => handleEditPlaylist()}
                          OnTouchStart={() => handleEditPlaylist()}

                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black mb-20"></div>
            </>
          ) : null}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            closeOnTouchStart
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
          />
        </main>
      </div>
    </div>
  );
}};
