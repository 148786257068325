/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import { ApiGet } from "../../helpers/API/ApiData";
import renderHTML from "react-render-html";
import CookieConsent, { Cookies } from "react-cookie-consent";

export default function Privacypolicy() {
  const [loading, setLoading] = useState(false);
  const [FaqData, setFaqData] = useState();
  const history = useNavigate();

  ////////////////////////////////////// Terms and conditions  ////////////////////////////////////

  useEffect(() => {
    const getAllFaq = async () => {
      setLoading(true);
      await ApiGet("privacy/get-all-privacy")
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data.data);
            setFaqData(res.data.data);
            setLoading(true);
          } else {
            console.log("error");
          }
        })
        .catch((err) => {
          setLoading(true);
          console.log(err);
        });
    };
    getAllFaq();
  }, []);

  //////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      <Header />
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          {FaqData ? (
            FaqData?.slice(0)
              ?.reverse()
              .map((data, key) => {
                return (
                  <>
                    <div className="pt-24 pb-6">
                      <div className="pl-10 pr-10">
                        <h2 className="adobe-font font-size-25 green-text-color tracking-normal pb-2">
                          Privacy and Policies
                        </h2>
                      </div>
                    </div>
                    {data?.content.map((content, key) => {
                      return (
                        <div className="">
                          <div className="pl-10 pr-10">
                            <p className="adobe-font font-size-20 green-text-color font-medium tracking-normal text-left pb-2">
                              {content?.title}
                            </p>
                            <p className="font-size-18 white-text-color font-medium tracking-normal text-left pb-8">
                              {renderHTML(content?.description)
                                ? renderHTML(content?.description)
                                : ""}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </>
                );
              })
          ) : loading ? (
            <center>
              <div className="mt-36">
                <div className="dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </center>
          ) : (
            <></>
          )}
          <CookieConsent
            // enableDeclineButton={{ borderRadius: "5px", padding: "10px 30px 10px 30px", background: "#1DB954", color: "#fff", fontSize: "16px" }}
            flipButtons
            location="bottom"
            buttonText="Close"
            cookieName="myAwesomeCookieName2"
            style={{
              paddingLeft: "20px",
              background: "#142524",
              paddingRight: "50px",
            }}
            buttonStyle={{
              borderRadius: "4px",
              background: "#1DB954",
              color: "#fff",
              fontSize: "16px",
              paddingTop: "5px",
            }}
            expires={150}
          >
            7thCentury.co.uk and it’s partners use cookies and similar
            technology to create a more tailored experience for our users. This
            helps us deliver more relevant and useful content and information to
            our customers and site visitors. By using this site you agree to the
            use of this technology by 7th Century and it’s partners. To see more
            details about how we manage your data, click here.
            <NavLink
              to="/privacypolicy"
              onClick={(e) => {
                e.preventDefault();
                history(-1)
              }}
              className="pl-3 font-size-18 adobe-font green-text-color"
            >
              {" "}
              Privacy Policy{" "}
            </NavLink>
          </CookieConsent>
        </main>
      </div>
    </div>
  );
}
