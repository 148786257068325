/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../js/Trending/trending.scss";
import "../../../Components/styles/dashboard.scss";
import Sidebar from "../../Layout/Sidebar";
import Header from "../../Layout/Header";
import { NavLink, useNavigate } from "react-router-dom";
import { ApiPost } from "../../../helpers/API/ApiData";
import * as authUtil from "../../../utils/auth.util";
import * as userUtil from "../../../utils/user.util";
import LoadingBalls from "../../../Assets/LoadingBalls";
import Country from "../countries/Country";
import Userlogin from "../Login/Login";
import UserSignup from "../Login/Signup";
import Forgotpassword from "../Login/Forgotpassword";
import debounce from 'lodash.debounce';


const CreatePlaylist = (props) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [playlistData, setPlaylistData] = useState({});
  const [playlistErrors, setPlaylistErrors] = useState([]);
  const [forgotModal, setForgotModal] = useState(false);

  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const [signupErrors, setSignupErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [Idc, setIdc] = useState();
  const [selectionBox, setSelectionBox] = useState(true);
  const [formBox, setFormBox] = useState(false);
  const [signupFormData, setSignupFormData] = useState({});
  const [countryInit, setCountryInit] = useState();
  const [passwordSeen, setPasswordSeen] = useState(false);
  const handlePasswordSeen = () => {
    setPasswordSeen(!passwordSeen);
  };

  const validate = () => {
    let error = [];
    let formIsValid = true;

    if (!formData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    }
    if (!formData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }
    setErrors(error);
    return formIsValid;
  };

  const signupValidate = () => {
    let error = [];
    let formIsValid = true;

    if (!signupFormData.email) {
      formIsValid = false;
      error.email = "Please Enter Email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(signupFormData.email)
    ) {
      formIsValid = false;
      error.email = "Please Enter proper Email";
    }

    if (!signupFormData.firstName) {
      formIsValid = false;
      error["firstName"] = "Please Enter FirstName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.firstName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.lastName) {
      formIsValid = false;
      error["lastName"] = "Please Enter LastName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.lastName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }

    setSignupErrors(error);
    return formIsValid;
  };
  const handleSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (validate()) {
        const data = {
          email: formData.email,
          password: formData.password,
        };
        setLoading(true);
        await ApiPost("users/login", data)
          .then((res) => {
            if (res.data.message === "Successfully logged in.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setLoginModal(false);
              window.location.reload();
            } else if (res.msg) {
              setLoading(false);
              setErrors({ user: "User does not exist." });
            } else {
              setLoading(false);
              setErrors({ user: "Please check username or password" });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };

  const handleSignupSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (signupValidate()) {
        const data = {
          firstName: signupFormData.firstName,
          lastName: signupFormData.lastName,
          email: signupFormData.email,
          password: signupFormData.password,
          contact: signupFormData.contact,
          country: countryInit,
          countryCode: Idc,
        };
        setLoading(true);
        let err = [];

        await ApiPost("users/signup", data)
          .then((res) => {
            if (res?.data?.data === "Email has been already registered.") {
              err["error"] = res.data.data;
              setSignupErrors(err);
            }
            if (res.data.message === "Record Sucessfully Inserted.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setSignupModal(false);
              window.location.reload();
            } else {
              setLoading(false);
              setErrors({ user: res.msg });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSignupChange = (e) => {
    let { name, value } = e.target;

    setSignupFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const history = useNavigate();

  const handlePlaylistChange = (e) => {
    let { name, value } = e.target;
    setPlaylistData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const playlistValidate = () => {
    let error = [];
    let formIsValid = true;

    if (!playlistData.title) {
      formIsValid = false;
      error["details"] = "Please add Title";
    }
    if (!playlistData.isPrivate) {
      formIsValid = false;
      error["details"] = "Please select Privacy";
    }

    setPlaylistErrors(error);
    return formIsValid;
  };
  const createPlaylist = async () => {
    if (playlistValidate()) {
      const data = {
        playlist_name: playlistData.title,
        isPrivate: playlistData.isPrivate,
      };
      await ApiPost("playlist/create-playlist", data)
        .then((res) => {
          console.log("res");
          history("/playlist");
          setShowModal(false);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  return (
    <div style={{ paddingBottom: "6%" }}>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex pt-24 pt-24-mobile">
                <div className="md:w-full">
                  <div className="pt-2 flex items-center">
                    <NavLink to="/" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}>
                      <div className="flex">
                        <p>
                          <i className="pt-2 fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                        </p>
                        <p className="pl-3 font-size-25 white-text-color font-medium tracking-normal text-center">
                          Create Playlist
                        </p>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center mt-20 create-playlist-center">
                {showModal ? (
                  <>
                    <div
                      className="scale-animation px-20 pb-10 pt-5 flex flex-col items-center justify-center rounded-lg"
                      style={{ backgroundColor: "#061E1D" }}
                    >
                      <p className=" px-10 pb-3 font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                        Add New Playlist
                      </p>
                      <span
                        style={{
                          color: "red",
                          top: "0px",
                          fontSize: "12px",
                        }}
                      >
                        {playlistErrors["details"]}
                      </span>
                      <div className=" rounded-lg flex flex-col items-center justify-center">
                        <input
                          type="text"
                          style={{
                            backgroundColor: "#3E4E4E",
                            color: "#fff",
                            padding: "10px 20px",
                          }}
                          placeholder="Title"
                          name="title"
                          className="rounded-lg font-size-20"
                          onChange={(e) => handlePlaylistChange(e)}
                        />

                        <div className="flex">
                          <div className="flex items-center justify-center p-4">
                            <input
                              type="radio"
                              name="isPrivate"
                              style={{
                                backgroundColor: "#3E4E4E",
                                color: "#fff",
                              }}
                              className="rounded-lg font-size-20 cursor-pointer"
                              value="true"
                              onChange={(e) => handlePlaylistChange(e)}
                            />
                            <p className="pl-3 text-white font-size-22">
                              Private
                            </p>
                          </div>
                          <div className="flex items-center justify-center p-4">
                            <input
                              type="radio"
                              name="isPrivate"
                              style={{
                                backgroundColor: "#3E4E4E",
                                color: "#fff",
                              }}
                              placeholder="Title"
                              className="rounded-lg font-size-20 cursor-pointer"
                              value="false"
                              onChange={(e) => handlePlaylistChange(e)}
                            />
                            <p className="pl-3 text-white font-size-22">
                              Public
                            </p>
                          </div>
                        </div>
                      </div>
                      <button
                        className="login-modal-btn font-size-16 white-text-color font-normal tracking-normal cursor-pointer"
                        onClick={() => createPlaylist()}
                        OnTouchStart={() => createPlaylist()}

                      >
                        Done
                      </button>
                    </div>
                  </>
                ) : (
                  <div
                    className="p-10 rounded-lg"
                    style={{ backgroundColor: "#061E1D" }}
                  >
                    <p className=" px-10 pb-3 font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                      Add New Playlist
                    </p>
                    <div
                      className="p-3 rounded-lg flex items-center justify-center"
                      style={{ backgroundColor: "#3E4E4E" }}
                      onClick={() =>
                        authUtil.isLoggedIn()
                          ? setShowModal(true)
                          : setLoginModal(true)
                      }
                      OnTouchStart={() =>
                        authUtil.isLoggedIn()
                          ? setShowModal(true)
                          : setLoginModal(true)
                      }
                    >
                      <i className="fas fa-plus icon-font-size cursor-pointer text-white"></i>
                      <p className="pl-3 font-size-20 white-text-color font-medium tracking-normal text-center mb-0 cursor-pointer">
                        Add New Playlist
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {forgotModal ? (
            <Forgotpassword
              setSignupModal={setSignupModal}
              setLoginModal={setLoginModal}
              setForgotModal={setForgotModal}
            />
          ) : null}

          {loginModal ? (
            <Userlogin
              setSignupModal={setSignupModal}
              setLoginModal={setLoginModal}
              setForgotModal={setForgotModal}
            />
          ) : null}

          {signupModal ? (
            <UserSignup
              setSignupModal={setSignupModal}
              setLoginModal={setLoginModal}
              setForgotModal={setForgotModal}
            />
          ) : null}
          {loading && <LoadingBalls />}
        </main>
      </div>
    </div>
  );
};

export default CreatePlaylist;
