/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import { Tooltip } from "@material-ui/core";

export default function Music() {
  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex">
                <div className="md:w-full">
                  <div className="pt-5">
                    <p className="font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                      My Playlist
                    </p>
                  </div>
                </div>
              </div>
              <div className="cus-grid pt-4">
                <div className="grid-items">
                  <div className="singer-box">
                    <div className="singer-img">
                      <img
                        src={require("../../Assets/Images/singer.svg").default}
                        alt=""
                      />
                    </div>
                    <div className="singer-text p-5 mini-view-p-remove">
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                            Song Name
                          </p>
                          <p className="font-size-18 gray-text-color font-normal tracking-normal mb-0">
                            Singer Name
                          </p>
                        </div>
                        <div>
                          <Tooltip title="More" arrow>
                            <i className="fas fa-ellipsis-v cursor-pointer font-size-10 text-white p-3"></i>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-items">
                  <div className="singer-box">
                    <div className="singer-img">
                      <img
                        src={require("../../Assets/Images/singer.svg").default}
                        alt=""
                      />
                    </div>
                    <div className="singer-text p-5 mini-view-p-remove">
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                            Song Name
                          </p>
                          <p className="font-size-18 gray-text-color font-normal tracking-normal mb-0">
                            Singer Name
                          </p>
                        </div>
                        <div>
                          <Tooltip title="More" arrow>
                            <i className="fas fa-ellipsis-v cursor-pointer font-size-10 text-white p-3"></i>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-items">
                  <div className="singer-box">
                    <div className="singer-img">
                      <img
                        src={require("../../Assets/Images/singer.svg").default}
                        alt=""
                      />
                    </div>
                    <div className="singer-text p-5 mini-view-p-remove">
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                            Song Name
                          </p>
                          <p className="font-size-18 gray-text-color font-normal tracking-normal mb-0">
                            Singer Name
                          </p>
                        </div>
                        <div>
                          <Tooltip title="More" arrow>
                            <i className="fas fa-ellipsis-v cursor-pointer font-size-10 text-white p-3"></i>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-items">
                  <div className="singer-box">
                    <div className="singer-img">
                      <img
                        src={require("../../Assets/Images/singer.svg").default}
                        alt=""
                      />
                    </div>
                    <div className="singer-text p-5 mini-view-p-remove">
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                            Song Name
                          </p>
                          <p className="font-size-18 gray-text-color font-normal tracking-normal mb-0">
                            Singer Name
                          </p>
                        </div>
                        <div>
                          <Tooltip title="More" arrow>
                            <i className="fas fa-ellipsis-v cursor-pointer font-size-10 text-white p-3"></i>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <div className="play-table1">
                  <table width="100%" className="play-table-details1">
                    <tbody>
                      <tr>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-right">
                          <div className="flex items-center cursor-pointer justify-end">
                            <div className="pr-5">
                              <span className="font-size-16 white-text-color font-medium tracking-normal">
                                03:48
                              </span>
                            </div>
                            <div className="pr-5">
                              <i className="far fa-heart white-text-color"></i>
                            </div>
                            <div>
                              <i className="fas fa-ellipsis-v white-text-color"></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-right">
                          <div className="flex items-center cursor-pointer justify-end">
                            <div className="pr-5">
                              <span className="font-size-16 white-text-color font-medium tracking-normal">
                                03:48
                              </span>
                            </div>
                            <div className="pr-5">
                              <i className="far fa-heart white-text-color"></i>
                            </div>
                            <div>
                              <i className="fas fa-ellipsis-v white-text-color"></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="table-heighlite">
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-right">
                          <div className="flex items-center cursor-pointer justify-end">
                            <div className="pr-5">
                              <span className="font-size-16 white-text-color font-medium tracking-normal">
                                03:48
                              </span>
                            </div>
                            <div className="pr-5">
                              <i className="far fa-heart white-text-color"></i>
                            </div>
                            <div>
                              <i className="fas fa-ellipsis-v white-text-color"></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-right">
                          <div className="flex items-center cursor-pointer justify-end">
                            <div className="pr-5">
                              <span className="font-size-16 white-text-color font-medium tracking-normal">
                                03:48
                              </span>
                            </div>
                            <div className="pr-5">
                              <i className="far fa-heart white-text-color"></i>
                            </div>
                            <div>
                              <i className="fas fa-ellipsis-v white-text-color"></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-right">
                          <div className="flex items-center cursor-pointer justify-end">
                            <div className="pr-5">
                              <span className="font-size-16 white-text-color font-medium tracking-normal">
                                03:48
                              </span>
                            </div>
                            <div className="pr-5">
                              <i className="far fa-heart white-text-color"></i>
                            </div>
                            <div>
                              <i className="fas fa-ellipsis-v white-text-color"></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-right">
                          <div className="flex items-center cursor-pointer justify-end">
                            <div className="pr-5">
                              <span className="font-size-16 white-text-color font-medium tracking-normal">
                                03:48
                              </span>
                            </div>
                            <div className="pr-5">
                              <i className="far fa-heart white-text-color"></i>
                            </div>
                            <div>
                              <i className="fas fa-ellipsis-v white-text-color"></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-right">
                          <div className="flex items-center cursor-pointer justify-end">
                            <div className="pr-5">
                              <span className="font-size-16 white-text-color font-medium tracking-normal">
                                03:48
                              </span>
                            </div>
                            <div className="pr-5">
                              <i className="far fa-heart white-text-color"></i>
                            </div>
                            <div>
                              <i className="fas fa-ellipsis-v white-text-color"></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-right">
                          <div className="flex items-center cursor-pointer justify-end">
                            <div className="pr-5">
                              <span className="font-size-16 white-text-color font-medium tracking-normal">
                                03:48
                              </span>
                            </div>
                            <div className="pr-5">
                              <i className="far fa-heart white-text-color"></i>
                            </div>
                            <div>
                              <i className="fas fa-ellipsis-v white-text-color"></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-right">
                          <div className="flex items-center cursor-pointer justify-end">
                            <div className="pr-5">
                              <span className="font-size-16 white-text-color font-medium tracking-normal">
                                03:48
                              </span>
                            </div>
                            <div className="pr-5">
                              <i className="far fa-heart white-text-color"></i>
                            </div>
                            <div>
                              <i className="fas fa-ellipsis-v white-text-color"></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-right">
                          <div className="flex items-center cursor-pointer justify-end">
                            <div className="pr-5">
                              <span className="font-size-16 white-text-color font-medium tracking-normal">
                                03:48
                              </span>
                            </div>
                            <div className="pr-5">
                              <i className="far fa-heart white-text-color"></i>
                            </div>
                            <div>
                              <i className="fas fa-ellipsis-v white-text-color"></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-right">
                          <div className="flex items-center cursor-pointer justify-end">
                            <div className="pr-5">
                              <span className="font-size-16 white-text-color font-medium tracking-normal">
                                03:48
                              </span>
                            </div>
                            <div className="pr-5">
                              <i className="far fa-heart white-text-color"></i>
                            </div>
                            <div>
                              <i className="fas fa-ellipsis-v white-text-color"></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-left">
                          <span className="font-size-16 white-text-color tracking-normal font-medium">
                            Song Name
                          </span>
                        </td>
                        <td className="text-right">
                          <div className="flex items-center cursor-pointer justify-end">
                            <div className="pr-5">
                              <span className="font-size-16 white-text-color font-medium tracking-normal">
                                03:48
                              </span>
                            </div>
                            <div className="pr-5">
                              <i className="far fa-heart white-text-color"></i>
                            </div>
                            <div>
                              <i className="fas fa-ellipsis-v white-text-color"></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
