/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React from "react";
import "../../Components/Plan/plan.scss";

export default function Plan() {
  return (
    <div>
      <div className="plan-box flex items-center justify-center">
        <div className="md:container md:mx-auto">
          <div className="lg:flex">
            <div className="lg:w-1/2 pl-3 pr-3">
              <div className="plan-department flex items-center justify-center">
                <p className="font-size-20 tracking-normal white-text-color font-medium mb-0">
                  Artist plans
                </p>
              </div>
              <div className="child-box-plan">
                <div className="md:flex">
                  <div className="md:w-1/2 pl-3 pr-3 border-right-plan">
                    <p className="font-size-20 tracking-normal text-center mt-8 cursor-pointer font-medium green-text-color mb-0">
                      Free
                    </p>
                    <p className="font-size-18 tracking-normal white-text-color font-normal mb-0 text-center mt-8">
                      3 free uploads
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center">
                      (singles or albums)
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      3 days on Trending pages
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      Artist Events invites
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      Limited Playlists
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      Free Downloads
                    </p>
                    <p className="mb-12 font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      New Releases
                    </p>
                  </div>
                  <div className="md:w-1/2 pl-3 pr-3">
                    <p className="font-size-20 tracking-normal text-center mt-8 cursor-pointer font-medium green-text-color mb-0">
                      Premium
                    </p>
                    <p className="font-size-18 tracking-normal white-text-color font-normal mb-0 text-center mt-8">
                      Unlimited uploads
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center">
                      (singles or albums)
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      Unlimited Playlists
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      Exclusive Artist Event Invites
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      7 days on Trending Pages
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      7 Days Featured on Home Page
                    </p>
                    <p className="mb-12 font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      Apparel & clothing (2 products)
                    </p>
                  </div>
                </div>
                <p className="font-size-30 white-text-color font-normal tracking-normal text-center pt-16">
                  00 / Year
                </p>
                <div className="flex justify-center pt-5">
                  <button className="preminum-btn-style white-text-color font-medium tracking-normal text-center">
                    Get Premium
                  </button>
                </div>
                <div className="pt-16"></div>
              </div>
            </div>
            <div className="lg:w-1/2 pl-3 pr-3">
              <div className="plan-department flex items-center justify-center">
                <p className="font-size-20 tracking-normal white-text-color font-medium mb-0">
                  Fans plan
                </p>
              </div>
              <div className="child-box-plan">
                <div className="md:flex">
                  <div className="md:w-1/2 pl-3 pr-3 border-right-plan">
                    <p className="font-size-20 tracking-normal text-center mt-8 cursor-pointer font-medium green-text-color mb-0">
                      Free
                    </p>
                    <p className="font-size-18 tracking-normal white-text-color font-normal mb-0 text-center mt-8">
                      3 free uploads
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center">
                      (singles or albums)
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      3 days on Trending pages
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      Artist Events invites
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      Limited Playlists
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      Free Downloads
                    </p>
                    <p className="mb-12 font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      New Releases
                    </p>
                  </div>
                  <div className="md:w-1/2 pl-3 pr-3">
                    <p className="font-size-20 tracking-normal text-center mt-8 cursor-pointer font-medium green-text-color mb-0">
                      Premium
                    </p>
                    <p className="font-size-18 tracking-normal white-text-color font-normal mb-0 text-center mt-8">
                      Unlimited uploads
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center">
                      (singles or albums)
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      Unlimited Playlists
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      Exclusive Artist Event Invites
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      7 days on Trending Pages
                    </p>
                    <p className="font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      7 Days Featured on Home Page
                    </p>
                    <p className="mb-12 font-size-18 white-text-color font-normal tracking-normal text-center pt-5">
                      Apparel & clothing (2 products)
                    </p>
                  </div>
                </div>
                <p className="font-size-30 white-text-color font-normal tracking-normal text-center pt-16">
                  00 / Year
                </p>
                <div className="flex justify-center pt-5">
                  <button className="preminum-btn-style white-text-color font-medium tracking-normal text-center">
                    Get Premium
                  </button>
                </div>
                <div className="pt-16"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
