/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */

/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import { useState, useEffect } from 'react';

import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { ApiGet, ApiPost } from "./../../helpers/API/ApiData";
import * as userUtils from "../../utils/user.util";


const useHandleDownload = () => {
    const [isLoading, setLoading] = useState();
    const userId = userUtils.getUserInfo()?.user?.id;

    const downloadUserList = async (data) => {
        console.log("-");
        const body = {
          musicId: data._id,
          userId: userId,
          artistId: data.artistData._id,
        };
    
        await ApiPost(
          `musicDownloadListForArtist/create-musicDownloadListForArtist`,
          body
        )
          .then((res) => {
            console.log("-");
          })
          .catch((err) => {
            console.log("err", err);
          });
      };

    const handledownload = async (data) => {
        let url = data?.musicUrl;
        let title = data?.musicTitle ? data?.musicTitle : data?.title;
        toast.success("Your download will begin shortly");
        setLoading(true);
        await axios({
          url: url,
          method: "GET",
          responseType: "blob",
          headers: {
            "Content-Type": "audio/mpeg",
            "Access-Control-Allow-Origin": "*",
          },
        })
          .then(async (response) => {
            // handleSendEmail(data._id);
            setLoading(false);
            console.log("Response", response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${title}.mp3`); //or any other extension
            document.body.appendChild(link);
            link.click();
            downloadUserList(data);
            await downloadMusic(data._id);
          })
          .catch((err) => {
            setLoading(false);
            console.log("errDownload", err);
          });
      };

      const downloadMusic = async (id) => {
        console.log("Downloading");
        await ApiGet(`music/download?musicId=${id}`)
          .then((res) => {
            console.log("Downloads", res);
          })
          .catch((err) => {
            console.log("err", err);
          });
      };

      return {
        downloadUserList,
        handledownload,
        isLoading,
      };
};


export default useHandleDownload;
