import { MUSIC_BY_COUNTER_INSERT } from "./actionType";
const initialStore = {};

const MusicByCountry = (state = initialStore, action) => {
  switch (action.type) {
    case MUSIC_BY_COUNTER_INSERT:
      return {...action.payload};
    default:
      return state;
  }
};

export default MusicByCountry;
