/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import "../News/News.scss";

export default function HeadingChild() {
  const history = useNavigate();
  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex">
                <div className="md:w-full">
                  <p className="font-size-30 pt-5 cursor-pointer white-text-color font-normal tracking-normal text-center">
                    Heading
                  </p>
                </div>
              </div>
            </div>
            <section className="pt-8">
              <div className="container-fluid">
                <div className="md:flex items-center">
                  <div className="md:w-1/2 pl-3 pr-3">
                    <NavLink to="/headingtext" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}>
                      <div>
                        <p className="font-size-16 white-text-color font-normal tracking-normal mb-0 cursor-pointer">
                          project and event name
                        </p>
                        <div className="reading-img pt-3">
                          <img
                            className="w-full"
                            src={
                              require("../../../Assets/Images/reading.png")
                                .default
                            } alt=""
                          />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="md:w-1/2 pl-3 pr-3">
                    <p className="font-size-16 tracking-normal white-text-color font-normal cursor-pointer">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum dolor
                      sit amet. Lorem ipsum dolor sit , no sea takimat Lorem
                      ipsum dolor sit amet, consetetur sadipscing elitr, sed
                      diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum dolor
                      sit amet. Lorem ipsum dolor sit , …...
                    </p>
                    <p className="font-size-18 link-text-color font-normal tracking-normal cursor-pointer mb-0 pt-3">
                      READ MORE
                    </p>
                  </div>
                </div>
                <div className="md:flex items-center pt-12">
                  <div className="md:w-1/2 pl-3 pr-3">
                    <p className="font-size-16 tracking-normal white-text-color font-normal cursor-pointer">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum dolor
                      sit amet. Lorem ipsum dolor sit , no sea takimat Lorem
                      ipsum dolor sit amet, consetetur sadipscing elitr, sed
                      diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum dolor
                      sit amet. Lorem ipsum dolor sit , …...
                    </p>
                    <p className="font-size-18 link-text-color font-normal tracking-normal cursor-pointer mb-0 pt-3">
                      READ MORE
                    </p>
                  </div>
                  <div className="md:w-1/2 pl-3 pr-3">
                    <div>
                      <p className="font-size-16 white-text-color font-normal tracking-normal mb-0 cursor-pointer">
                        project and event name
                      </p>
                      <div className="reading-img pt-3">
                        <img
                          className="w-full"
                          src={
                            require("../../../Assets/Images/reading1.png")
                              .default
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
}
