import { NEW_RELESE_INSERT } from "./actionType";
const initialStore = [];

const NewRelease = (state = initialStore, action) => {
  switch (action.type) {
    case NEW_RELESE_INSERT:
      return [...action.payload];
    default:
      return state;
  }
};

export default NewRelease;
