/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import { MUSIC_DATA_INSERT } from "./actionType";

export const getMusicData = (musicData) => {
  return {
    payload: musicData,
    type: MUSIC_DATA_INSERT,
  };
};

export const getFreeMusicData = (musicData) => {
  const data = musicData.playlistData.filter(music => !music.isPaid);
  return {
    payload: {
      playlistData: data
    },
    type: MUSIC_DATA_INSERT,
  };
};
