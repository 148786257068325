/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { ApiGet, ApiPost } from "../../helpers/API/ApiData";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import * as authUtil from "../../utils/auth.util";
import * as userUtil from "../../utils/user.util";
import LoadingBalls from "../../Assets/LoadingBalls";
import Country from "../js/countries/Country";
import "../Shop/shop.scss";
import Userlogin from "../js/Login/Login";
import UserSignup from "../js/Login/Signup";
import Forgotpassword from "../js/Login/Forgotpassword";
import debounce from 'lodash.debounce';


export default function Product() {
  const [productData, setProductData] = useState();
  const history = useNavigate();
  const [Idc, setIdc] = useState();
  const [forgotModal, setForgotModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const [signupErrors, setSignupErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [signupFormData, setSignupFormData] = useState({});
  const [countryInit, setCountryInit] = useState();
  const [passwordSeen, setPasswordSeen] = useState(false);
  const [loader, setLoader] = useState(false);
  const handlePasswordSeen = () => {
    setPasswordSeen(!passwordSeen);
  };

  const handleLoginCheck = (id) => {
    console.log(authUtil.isLoggedIn());
    if (authUtil.isLoggedIn() === false) {
      setLoginModal(true);
    } else {
      history(`/cart/${id}`);
    }
  };

  const validate = () => {
    let error = [];
    let formIsValid = true;
    if (!formData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    }
    if (!formData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }
    setErrors(error);
    return formIsValid;
  };
  const signupValidate = () => {
    let error = [];
    let formIsValid = true;
    if (!signupFormData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(signupFormData.email)
    ) {
      formIsValid = false;
      error["email"] = "Please Enter proper Email";
    }

    if (!signupFormData.firstName) {
      formIsValid = false;
      error["firstName"] = "Please Enter FirstName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.firstName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.lastName) {
      formIsValid = false;
      error["lastName"] = "Please Enter LastName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.lastName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }
    setSignupErrors(error);
    return formIsValid;
  };
  const handleSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (validate()) {
        const data = {
          email: formData.email,
          password: formData.password,
        };
        setLoading(true);
        await ApiPost("users/login", data)
          .then((res) => {
            if (res.data.message === "Successfully logged in.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setLoginModal(false);
              window.location.reload();
            } else if (res.msg) {
              setLoading(false);
              setErrors({ user: "User does not exist." });
            } else {
              setLoading(false);
              setErrors({ user: "Please check username or password" });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };
  const handleSignupSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (signupValidate()) {
        const data = {
          firstName: signupFormData.firstName,
          lastName: signupFormData.lastName,
          email: signupFormData.email,
          password: signupFormData.password,
          contact: signupFormData.contact,
          country: countryInit,
          countryCode: Idc,
        };
        setLoading(true);
        let err = [];

        await ApiPost("users/signup", data)
          .then((res) => {
            if (res?.data?.data === "Email has been already registered.") {
              err["error"] = res.data.data;
              setSignupErrors(err);
            }
            if (res.data.message === "Record Sucessfully Inserted.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setSignupModal(false);
              window.location.reload();
            } else {
              setLoading(false);
              setErrors({ user: res.msg });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSignupChange = (e) => {
    let { name, value } = e.target;
    setSignupFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getAllProduct = async () => {
    setLoader(true);
    await ApiGet("product/get-all-products")
      .then((res) => {
        console.log(res.data.data._id);
        setProductData(res.data.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    getAllProduct();
  }, []);
  return (
    <>
      <div>
        <div className="flex">
          <>
            {" "}
            <Header />
            <Sidebar />
          </>
          <main className="s-layout__content">
            <div className="bg-white full-height-product">
              <div className="pt-5 mt-6">
                <div className="ml-6 mt-6">
                  <NavLink to="/shop" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}>
                    <p>
                      <i className="fas fa-arrow-left cursor-pointer icon-font-size text-black pt-6"></i>
                    </p>
                  </NavLink>
                </div>
                {loader && (
                  <div className="dots">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                )}

                <div className="fix-prduct-box">
                  <div className="md:flex pt-8 md:flex-wrap">
                    {productData &&
                      productData
                        ?.slice(0)
                        ?.reverse()
                        .map((data, key) => {
                          return (
                            <div className="lg:w-1/5 lg:flex-wrap md:w-1/2 pl-4 pr-4 mb-5 md:flex-wrap">
                              <div className="cloth-main-box relative">
                                <div className="cloth-img">
                                  <img
                                    //   src={require("../../Assets/Images/cloth01.png").default}
                                    src={data.image} alt=""
                                  />
                                </div>
                                <div className="cloth-profile-after">
                                  <div className="flex items-center justify-center mb-8 w-full">
                                    <div
                                      className="cloth-after-icon flex ml-3 items-center justify-center cursor-pointer"
                                      onClick={() => {
                                        handleLoginCheck(data._id);
                                      }}
                                      OnTouchStart={() => {
                                        handleLoginCheck(data._id);
                                      }}
                                    >
                                      <i className="black-icon-color font-size-22  far fa-eye"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pt-4 cursor-pointer">
                                <p className="font-size-20 headnig-title-text-color font-medium tracking-normal">
                                  {data.title}
                                </p>
                                <p className="prize-text-color italic font-bold tracking-normal mb-0 font-size-20">
                                  $ {data.price}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      {forgotModal ? (
        <Forgotpassword
          setSignupModal={setSignupModal}
          setLoginModal={setLoginModal}
          setForgotModal={setForgotModal}
        />
      ) : null}

      {loginModal ? (
        <Userlogin
          setSignupModal={setSignupModal}
          setLoginModal={setLoginModal}
          setForgotModal={setForgotModal}
        />
      ) : null}

      {signupModal ? (
        <UserSignup
          setSignupModal={setSignupModal}
          setLoginModal={setLoginModal}
          setForgotModal={setForgotModal}
        />
      ) : null}
      {loading && <LoadingBalls />}
    </>
  );
}
