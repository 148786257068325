/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
const protocol = "https";
const host = "api.7thcentury.co.uk/api";

// const protocol = "http";
// const host = "192.168.29.50:5500/api";

// const host = "api.7cmg.rejoicehub.com/api"; 
// const host = "localhost:5500/api";

const port = "";
const trailUrl = "";

const hostUrl = `${protocol}://${host}${port ? ":" + port : ""}/`;
const endpoint = `${protocol}://${host}${port ? ":" + port : ""}${trailUrl}`;

export default {
  protocol: protocol,
  host: host,
  port: port,
  apiUrl: trailUrl,
  endpoint: endpoint,
  hostUrl: hostUrl,
};
