import { ALL_FREE_INSERT } from "./actionType";
const initialStore = [];

const AllFree = (state = initialStore, action) => {
  switch (action.type) {
    case ALL_FREE_INSERT:
      return [...action.payload];
    default:
      return state;
  }
};

export default AllFree;
